
.sidebar__single {
    padding: 25px 32px;
    background-color: var(--acn-theme-white3, #f1f6f7);
    border-radius: 20px;
}
.sidebar__title {
    position: relative;
    margin-bottom: 15px;
    margin-top : 0;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 1.363;
}
.sidebar__title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 39px;
    height: 3px;
    background-color: var(--acn-theme-base, #E6B088FF);
}

.sidebar__categories__icon {
    margin-right: 5px;
    font-size: 14px;
}

.sidebar__categories__text {
    font-size: 14px;
}

.sidebar__categories li {
    line-height: 25px !important;
}