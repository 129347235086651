.search-popup__form {
    position: relative;
}
.search-popup__form input[type=search],
.search-popup__form input[type=text] {
    width: 100%;
    background-color: var(--acn-theme-white, #ffffff);
    font-size: 13px;
    font-weight: 400;
    color: var(--acn-theme-text, #636363);
    border: 1px solid;
    outline: none;
    height: 35.5px;
    padding-left: 16px;
    border-radius: 60px;
    transition: all 500ms ease;
}
.search-popup__form input[type=search]:focus,
.search-popup__form input[type=text]:focus {
    color: var(--acn-theme-black, #122f2a);
}
.search-popup__form .acn-theme-btn {
    padding: 0;
    width: 35.5px;
    height: 35.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: center;
    position: absolute;
    top: 0;
    right: -1px;
    border-radius: 0 60px 60px 0;
    border : 1px solid;
}
.search-popup__form .acn-theme-btn svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
}
.search-popup.active {
    z-index: 9999;
}