.loader-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(255,255,255,0.9);
    z-index: 99999;
    top : 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loader {
    width: 50px;
    height: 50px;
    position: relative;
    animation: rotate 1s linear infinite
}

.loading-text {
    font-size: 22px;
}