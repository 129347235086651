.blog-item-img {
    width: 100%;
    scale : 1;
    transition: 500ms all ease;
}

.blog-item-article {
    padding: 10px;
    cursor: pointer;
}

.blog-item-article .post-category{
    transition: all 0.3s ease;
    background-color: var(--theme-color-extra_bg_color);
    border-radius: 10px;
    color: var(--theme-color-extra_dark) !important;
    padding: 3px 11px 3px 12px;
}

.blog-item-article:hover .blog-item-img {
    scale : 1.1;
    transition: 500ms all ease;
}
.blog-item-article:hover .post-category {
    background-color: var(--theme-color-text_link);;
    transition: 300ms all ease;
}
.blog-item-info  {
    position: absolute;
    bottom: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: end;
    padding: 30px;
}

.blog-item-info .post_title a {
    color : white !important;
}