
.item-picker-select .form-control-select__menu-list {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
}

.item-picker-select  .icon-option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.item-picker-select  .form-control-select__single-value .icon-option {
    justify-content: left;
    align-items: left;
}