

.post-pagination {
    list-style: none;
    padding: 0;
    margin: 30px 0 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
.post-pagination a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    background-color: var(--acn-theme-white3, #f1f6f7);
    color: var(--acn-theme-text, #636363);
    text-transform: capitalize;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
    line-height: 43px;
    overflow: hidden;
    transition: all 400ms ease;
}
.post-pagination a .post-pagination__icon {
    display: inline-flex;
    font-size: 12px;
    color: var(--acn-theme-text, #636363);
    transition: all 400ms ease;
}
.post-pagination a .post-pagination__icon svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
}
.post-pagination a:hover {
    color: var(--acn-theme-white, #ffffff) !important;
    background-color: var(--acn-theme-base, #ee9982);
}
.post-pagination a:hover .post-pagination__icon {
    color: var(--acn-theme-white, #ffffff);
    animation: slideLeft 400ms;
}
.post-pagination li.active a {
    color: var(--acn-theme-white, #ffffff);
    background-color: var(--acn-theme-base, #ee9982);
}
.post-pagination li.active a .post-pagination__icon {
    color: var(--acn-theme-white, #ffffff);
}

.post-pagination {
    list-style: none !important;
}