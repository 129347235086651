
.insurance-sidebar__single + .insurance-sidebar__single {
    margin-top: 50px;
}
@media (max-width: 1399px) {
    .insurance-sidebar__single + .insurance-sidebar__single {
        margin-top: 30px;
    }
}
.insurance-sidebar__single {
    padding: 10px 12px 15px;
    background-color: var(--acn-theme-white2, #ecf2f2);
    border-radius: 10px;
}
.insurance-sidebar__title {
    margin-bottom: 21px;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.454;
}
.insurance-sidebar__nav {
    margin: 0;
}
.insurance-sidebar__nav li + li {
    margin-top: 20px;
}
.insurance-sidebar__nav li a {
    position: relative;
    padding: 12px 35px 12px 15px;
    font-size: 14px;
    font-weight: 500;
    color: var(--acn-theme-black2, #333333);
    line-height: 1.5;
    display: flex;
    align-items: center;
    border-radius: 25px;
    background-color: var(--acn-theme-white, #ffffff);
    z-index: 1;
    overflow: hidden;
    transition: all 500ms ease;
}
.insurance-sidebar__nav li a::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0%;
    height: 100%;
    border-radius: inherit;
    background-color: var(--acn-theme-base, #ee9982);
    z-index: -1;
    transition: all 500ms ease;
}
.insurance-sidebar__nav li a::after {
    content: "\f054";
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    font-family: "Font Awesome 5 Free";
    font-size: 11px;
    color: var(--acn-theme-black2, #333333);
    font-weight: 900;
    transition: all 500ms ease;
}
.insurance-sidebar__nav li a .insurance-sidebar__nav__icon {
    margin-right: 11px;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    font-size: 14px;
    color: var(--acn-theme-white, #ffffff);
    background-color: var(--acn-theme-black8, #202221);
    transition: all 500ms ease;
}
.insurance-sidebar__nav li a .insurance-sidebar__nav__icon svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
}
.insurance-sidebar__nav li.current a, .insurance-sidebar__nav li:hover a {
    color: var(--acn-theme-white, #ffffff) !important;
}
.insurance-sidebar__nav li.current a::before, .insurance-sidebar__nav li:hover a::before {
    width: 100%;
    left: 0;
}
.insurance-sidebar__nav li.current a::after, .insurance-sidebar__nav li:hover a::after {
    color: var(--acn-theme-white, #ffffff);
}
.insurance-sidebar__nav li.current a .insurance-sidebar__nav__icon, .insurance-sidebar__nav li:hover a .insurance-sidebar__nav__icon {
    color: var(--acn-theme-base, #ee9982);
    background-color: var(--acn-theme-white, #ffffff);
}
.insurance-sidebar__btn + .insurance-sidebar__btn {
    margin-top: 20px;
}
.insurance-sidebar__btn {
    position: relative;
    padding: 12px 50px 12px 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    border-radius: 25px;
    background-color: var(--acn-theme-white, #ffffff);
    font-size: 16px;
    font-weight: 600;
    color: var(--acn-theme-black2, #333333);
    line-height: 1.5;
    z-index: 1;
    overflow: hidden;
    transition: all 500ms ease;
}
@media (max-width: 375px) {
    .insurance-sidebar__btn {
        gap: 12px;
    }
}
.insurance-sidebar__btn::after {
    content: "\e921";
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    font-size: 15px;
    color: inherit;
}
.insurance-sidebar__btn::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0%;
    height: 100%;
    border-radius: inherit;
    background-color: var(--acn-theme-base, #ee9982);
    z-index: -1;
    transition: all 500ms ease;
}
.insurance-sidebar__btn:hover {
    color: var(--acn-theme-white, #ffffff);
}
.insurance-sidebar__btn:hover::before {
    width: 100%;
    left: 0;
}
.insurance-sidebar__btn__icon {
    display: inline-flex;
    font-size: 26px;
    color: var(--acn-theme-base, #ee9982);
    transition: all 500ms ease;
}
.insurance-sidebar__btn__icon svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
}
.insurance-sidebar__btn:hover .insurance-sidebar__btn__icon {
    color: var(--acn-theme-white, #ffffff);
}