

.flex-direction-right {
    flex-direction: row-reverse !important;
}

.flex-direction-left {
    flex-direction: row !important;
}


.flex-direction-right > :last-child {
    padding-right: 20px !important
}

.flex-direction-left > :last-child {
    padding-left: 20px !important
}

@media (max-width: 767px) {
    .flex-direction-right > :last-child {
        padding-top : 20px !important;
        padding-right: 0 !important
    }

    .flex-direction-left > :last-child {
        padding-top : 20px !important;
        padding-left: 0 !important
    }

    .col-xs-12 {
        width: 100% !important;
    }

    .col-xs-6 {
        width: 50% !important;
    }

    .flex-direction-left, .flex-direction-right {
        flex-direction: column !important;
    }

    .page-container {
        zoom: 0.8 !important;
    }
}

.page-container-header {
    padding-top : 100px;
    padding-bottom: 100px;
}

.page-container-header .page-header__bg {
    -webkit-mask-image: -webkit-gradient(linear, right top, left bottom,
    from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    opacity: 1 !important;
}

.page-element-container {
    gap : 20px !important;
    position: relative;
}

.row-overflow-center {
    justify-content: center;
}
.row-overflow-flex-start {
    justify-content: flex-start;
}

.element-background-container {
    position: absolute;
    left: -20px;
    right: -20px;
    border-radius: 15px;
    background-color: #fff8f8;
    z-index: -1;
    top: 0;
    bottom: 0;
}

.element-background-container.full-bg {
    left : -100%;
    right: -100%;
}