.skocak *, .skocak :after, .skocak :before {
    box-sizing: border-box
}

.skocak a {
    box-shadow: none;
    text-decoration: none
}

.skocak img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none
}

.skocak .skocak-background-overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute
}

.skocak-element {
    --flex-direction: initial;
    --flex-wrap: initial;
    --justify-content: initial;
    --align-items: initial;
    --align-content: initial;
    --gap: initial;
    --flex-basis: initial;
    --flex-grow: initial;
    --flex-shrink: initial;
    --order: initial;
    --align-self: initial;
    flex-basis: var(--flex-basis);
    flex-grow: var(--flex-grow);
    flex-shrink: var(--flex-shrink);
    order: var(--order);
    align-self: var(--align-self)
}

.skocak-element:where(.e-con-full,.skocak-widget) {
    flex-direction: var(--flex-direction);
    flex-wrap: var(--flex-wrap);
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    align-content: var(--align-content);
    gap: var(--gap)
}

.skocak-section {
    position: relative
}

.skocak-section .skocak-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative
}

@media (max-width: 1023px) {
    .skocak-section .skocak-container {
        flex-wrap: wrap
    }
}

.skocak-section.skocak-section-boxed > .skocak-container {
    max-width: 1140px
}

.skocak-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start
}

.skocak:not(.skocak-bc-flex-widget) .skocak-widget-wrap {
    display: flex
}

.skocak-widget-wrap > .skocak-element {
    width: 100%
}

.skocak-widget {
    position: relative
}

.skocak-widget:not(:last-child) {
    margin-bottom: 20px
}

.skocak-column {
    position: relative;
    min-height: 1px;
    display: flex
}

.skocak-column-gap-default > .skocak-column > .skocak-element-populated {
    padding: 10px
}

.skocak-column-gap-extended > .skocak-column > .skocak-element-populated {
    padding: 15px
}

.skocak-column-gap-wide > .skocak-column > .skocak-element-populated {
    padding: 20px
}

.skocak-inner-section .skocak-column-gap-no .skocak-element-populated {
    padding: 0
}

@media (min-width: 768px) {
    .skocak-column.skocak-col-25 {
        width: 25%
    }

    .skocak-column.skocak-col-33 {
        width: 33.333%
    }

    .skocak-column.skocak-col-50 {
        width: 50%
    }

    .skocak-column.skocak-col-66 {
        width: 66.666%
    }

    .skocak-column.skocak-col-100 {
        width: 100%
    }
}

@media (max-width: 767px) {
    .skocak-column {
        width: 100%
    }
}

.skocak-element {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px
}

.skocak-column .skocak-spacer-inner {
    height: var(--spacer-size)
}

.skocak-section.skocak-section-boxed > .skocak-container {
    max-width: 1320px
}

.skocak-widget:not(:last-child) {
    margin-block-end: 20px
}

.skocak-element {
    --widgets-spacing: 20px 20px
}

@media (max-width: 1023px) {
    .skocak-section.skocak-section-boxed > .skocak-container {
        max-width: 1024px
    }
}

@media (max-width: 767px) {
    .skocak-section.skocak-section-boxed > .skocak-container {
        max-width: 767px
    }
}

:root {
    --fixed-rows-height: 0px
}

html {
    overflow-y: scroll !important
}

[class*=" trx_addons_icon-"]:before, [class^=trx_addons_icon-]:before {
    margin: 0;
    width: auto;
    font-size: inherit;
    line-height: inherit !important;
    font-weight: inherit
}

.post_meta_item {
    margin-left: .5em
}

.post_meta_item:before {
    line-height: inherit;
    margin: 0 .3em 0 0
}

.post_meta_item .post_meta_number + .post_meta_label {
    margin-left: .3em
}

.trx_addons_columns_wrap {
    margin-left: 0;
    margin-right: -30px
}

.trx_addons_columns_wrap > [class*=trx_addons_column-] {
    display: inline-block;
    vertical-align: top;
    position: relative;
    min-height: 1px;
    padding-left: 0;
    padding-right: 30px;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.trx_addons_columns_wrap.columns_padding_bottom > [class*=trx_addons_column-] {
    padding-bottom: 30px
}

.trx_addons_columns_wrap.columns_padding_bottom.columns_in_single_row > [class*=trx_addons_column-] {
    padding-bottom: 0
}

.trx_addons_column-1_3 {
    width: 33.3333333333%
}

.trx_addons_column-1_4 {
    width: 25%
}

.trx_addons_columns_wrap:after {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block
}

:root {
    --sc-space-tiny: 1rem;
    --sc-space-small: 2rem;
    --sc-space-medium: 3.3333rem;
    --sc-space-large: 6.6667rem;
    --sc-space-big: var(--sc-space-large);
    --sc-space-huge: 8.6667rem;
    --sc-shift-tiny: 2em;
    --sc-shift-small: 4em;
    --sc-shift-medium: 6em;
    --sc-shift-large: 8em
}

.sc_align_center {
    text-align: center
}

.sc_item_button, .sc_item_subtitle, .sc_item_title {
    margin: 0
}

.sc_item_title {
    line-height: 1.2em
}

.sc_item_subtitle {
    font-size: 1.15rem;
    line-height: 1.5em;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    letter-spacing: 0;
    display: block
}

.sc_item_subtitle_below {
    margin-top: .5em
}

.sc_item_button {
    line-height: 0
}

.sc_item_featured {
    position: relative
}

.sc_item_featured [class*=post_info_] {
    padding: .4em .8em;
    background-color: rgba(248, 248, 248, .85);
    z-index: 10 !important;
    max-width: 75%;
    -webkit-transition: background-color .3s ease;
    -ms-transition: background-color .3s ease;
    transition: background-color .3s ease
}

.sc_item_featured:hover [class*=post_info_] {
    background-color: #f7f7f7
}

.sc_item_featured [class*=post_info_] .post_meta {
    margin: 0
}

.sc_item_featured [class*=post_info_] .post_meta + .entry-title {
    margin-top: 8px
}

.sc_item_featured [class*=post_info_] .entry-title + .post_meta {
    margin-top: 8px
}

.sc_item_featured .post_info_bc {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center
}

.sc_item_featured.post_featured_bg[data-ratio]:before {
    content: ' ';
    width: 0;
    height: 0;
    display: inline-block;
    vertical-align: top;
    margin-left: -.3em
}

.sc_item_featured.post_featured_bg[data-ratio="16:9"]:before {
    padding-top: 56.25%
}

.sc_item_featured.post_featured_bg[data-ratio="1:1"]:before {
    padding-top: 100%
}

.sc_item_filters {
    margin-bottom: 2em
}

.sc_item_filters_align_right {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    border-bottom: 2px solid #333;
    padding-bottom: 1em
}

.sc_item_filters_align_right .sc_item_filters_tabs {
    max-width: 80%
}

.sc_item_filters_align_right.sc_item_filters_no_title {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.sc_item_filters_align_right .sc_item_filters_tabs {
    order: 1;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.sc_item_filters_tabs {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 1.15em;
    list-style-type: none !important;
    margin: 0 !important;
    padding: 0 !important
}

.sc_item_filters_tabs li {
    display: inline-block;
    padding: 0 0 0 1.5em
}

.sc_item_filters_align_right .sc_item_filters_tabs li {
    padding: 0 1.5em 0 0
}

.sc_item_filters_tabs li:before {
    display: none !important
}

.skocak-container > .skocak-column > .skocak-element-populated {
    padding-top: 0;
    padding-bottom: 0
}

:root {
    --trx-addons-parallax-x-anchor: center;
    --trx-addons-parallax-y-anchor: center
}

.skocak-section-content-middle .skocak-widget-wrap {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.sc_content_align_right.skocak-column .skocak-widget-wrap, .sc_content_align_right[class*=sc_inner_width_] {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.sc_layouts_row {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.sc_layouts_row .sc_layouts_item {
    margin-top: .5em !important;
    margin-bottom: .5em !important
}

.sc_layouts_item {
    max-width: 100%
}

.sc_layouts_column .sc_layouts_item:not(.skocak-widget-divider):not(.skocak-widget-spacer) {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.5em;
    width: auto !important
}

.sc_layouts_column_align_left .sc_layouts_item:last-child {
    margin-right: 0 !important
}

.sc_layouts_column_align_right .sc_layouts_item:not(.skocak-widget-divider):not(.skocak-widget-spacer) {
    margin-left: 1.5em;
    margin-right: 0
}

.sc_layouts_column_align_right .sc_layouts_item:not(.skocak-widget-divider):not(.skocak-widget-spacer):first-child {
    margin-left: 0 !important
}

.sc_layouts_column_align_center .sc_layouts_item:not(.skocak-widget-divider):not(.skocak-widget-spacer) {
    margin-left: .75em;
    margin-right: .75em
}

.sc_layouts_row .skocak-widget-spacer {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.sc_layouts_column .skocak-widget-spacer {
    width: 100%
}

.sc_layouts_column_align_left {
    text-align: left
}

.sc_layouts_column_align_left .skocak-widget-wrap {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.sc_layouts_column_align_center {
    text-align: center
}

.sc_layouts_column_align_center .skocak-widget-wrap {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.sc_layouts_column.sc_layouts_column_align_right .widget, .sc_layouts_column_align_right {
    text-align: right
}

.sc_layouts_column_align_left .skocak-widget-wrap {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.sc_layouts_column_align_center .skocak-widget-wrap {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.sc_layouts_column_align_right .skocak-widget-wrap {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

@media (min-width: 2160px) {
    .sc_layouts_hide_on_wide {
        display: none !important
    }
}

@media (min-width: 1680px) and (max-width: 2159px) {
    .sc_layouts_hide_on_desktop {
        display: none !important
    }
}

@media (min-width: 1280px) and (max-width: 1679px) {
    .sc_layouts_hide_on_notebook {
        display: none !important
    }
}

@media (min-width: 768px) and (max-width: 1279px) {
    .sc_layouts_hide_on_tablet {
        display: none !important
    }
}

@media (max-width: 767px) {
    .sc_layouts_hide_on_mobile {
        display: none !important
    }
}

.sc_layouts_item_link {
    display: block
}

.sc_layouts_item_icon {
    font-size: 2.25em;
    line-height: 1.1em;
    display: inline-block;
    vertical-align: middle;
    -webkit-transition: color .3s ease;
    -ms-transition: color .3s ease;
    transition: color .3s ease
}

.sc_layouts_row_type_compact {
    padding: .75em 0
}

.sc_layouts_row_type_compact .sc_layouts_item {
    letter-spacing: 0
}

.sc_layouts_row_type_compact .sc_layouts_item_icon {
    font-size: 1.25em;
    font-weight: 400
}

.sc_layouts_row_fixed_placeholder {
    display: none;
    position: relative
}

:root {
    --trx-addons-fixed-rows-duration: 0.4s
}

.sc_button_wrap:not([class*=sc_align_]) {
    display: inline-block;
    vertical-align: top
}

.sc_button {
    display: inline-block;
    vertical-align: top;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 1.5em 3em;
    background-position: center center;
    background-repeat: repeat;
    overflow: hidden;
    background-color: #efa758;
    color: #fff;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease
}

.sc_button:hover {
    background-color: #007fc5
}

.sc_button_icon, .sc_button_text {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    text-decoration: inherit
}

.sc_button_icon {
    font-size: 2em;
    line-height: 1.25em;
    font-weight: 400;
    font-style: normal
}

.sc_button_icon_left .sc_button_icon {
    margin-right: .8em
}

.sc_button.sc_button_size_small {
    padding: 1em 2.5em
}

.sc_button_simple {
    display: inline-block;
    background-color: transparent !important;
    color: #efa758;
    position: relative;
    padding: 0 1.8em 0 0
}

.sc_button_simple:hover {
    color: #000
}

.sc_button.sc_button_simple:after, .sc_button.sc_button_simple:before {
    content: '\e911';
    font-family: trx_addons_icons;
    margin: 0;
    line-height: inherit;
    color: inherit;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease
}

.sc_button.sc_button_simple:before {
    right: 20px;
    opacity: 0
}

.sc_button.sc_button_simple:hover:before {
    opacity: 1;
    right: 0
}

.sc_button.sc_button_simple:hover:after {
    opacity: 0;
    right: -20px
}

.sc_title {
    position: relative
}

.slider_container {
    position: relative;
    overflow: hidden
}

.slider_container:not(.inited) {
    display: none
}

.slider_outer {
    position: relative
}

.slider_container .slider-slide {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}

.slider_container:not(.slider_height_fixed) .slider-wrapper {
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.slider_container:not(.slider_height_fixed) .slider-slide {
    height: auto
}

.slider_outer .swiper-pagination {
    display: block;
    text-align: center;
    z-index: 9;
    color: #fff
}

.slider_outer.slider_outer_pagination_pos_bottom .swiper-pagination-bullets {
    left: 0;
    top: auto;
    bottom: 1em;
    width: 100%;
    height: 1.5em;
    line-height: 1.5em;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.sc_item_slider.slider_outer_pagination_pos_bottom .swiper-pagination {
    left: auto;
    bottom: auto;
    position: static;
    margin-top: 1em
}

:root {
    --sc-slider-controller-height: 100px
}

.sc_layouts_cart {
    position: relative;
    z-index: 2000
}

.sc_layouts_cart_icon {
    cursor: pointer
}

.sc_layouts_cart_widget {
    display: none;
    text-align: left;
    position: absolute;
    z-index: 2;
    top: 4em;
    right: -2em;
    width: 280px;
    padding: 1em;
    border: 1px solid #ddd;
    background-color: #fff;
    color: #888;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.sc_layouts_cart_widget:after {
    content: ' ';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 2.5em;
    width: 8px;
    height: 8px;
    margin: -5px 0 0 -4px;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.sc_layouts_logo img {
    max-height: 6em;
    width: auto;
    vertical-align: middle
}

.sc_layouts_row_type_compact .sc_layouts_logo img {
    max-height: 4.5em
}

.sc_layouts_menu_mobile_button {
    display: none !important
}

.sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    font-size: 2em
}

.sc_layouts_menu_mobile_button .sc_layouts_item_icon:before {
    line-height: 1.2em
}

.sc_layouts_menu {
    position: relative;
    z-index: 1000;
    overflow: hidden;
    max-height: 3em
}

.sc_layouts_menu.inited {
    overflow: visible;
    visibility: inherit;
    max-height: none
}

.sc_layouts_menu_nav, .sc_layouts_menu_nav ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style-type: none !important
}

.sc_layouts_menu_nav ul:after, .sc_layouts_menu_nav:after {
    content: ' ';
    clear: both;
    width: 100%;
    height: 0;
    display: block
}

.sc_layouts_menu_nav li {
    margin: 0;
    position: relative
}

.sc_layouts_menu_nav li > a {
    display: block;
    position: relative
}

.sc_layouts_menu_nav li > a:not([class*=icon-]):empty, .sc_layouts_menu_nav li > a > span:not([class*=icon-]):empty {
    display: none !important
}

.sc_layouts_menu_nav {
    text-align: left;
    vertical-align: middle;
    padding-left: 0 !important;
    margin-right: 25px !important;
}

.sc_layouts_menu_nav > li {
    position: relative;
    float: left;
    z-index: 20
}

.sc_layouts_menu_nav > li + li {
    z-index: 19
}

.sc_layouts_menu_nav > li + li + li {
    z-index: 18
}

.sc_layouts_menu_nav > li + li + li + li {
    z-index: 17
}

.sc_layouts_menu_nav > li + li + li + li + li {
    z-index: 16
}

.sc_layouts_menu_nav > li + li + li + li + li + li {
    z-index: 15
}

.sc_layouts_menu_nav > li + li + li + li + li + li + li {
    z-index: 14
}

.sc_layouts_menu_nav > li > a {
    padding: 1em;
    position: relative;
    color: #333;
    -webkit-transition: color .3s ease, background-color .3s ease, border-color .3s ease;
    -ms-transition: color .3s ease, background-color .3s ease, border-color .3s ease;
    transition: color .3s ease, background-color .3s ease, border-color .3s ease
}

.sc_layouts_menu_nav > li.current-menu-ancestor > a, .sc_layouts_menu_nav > li > a:hover {
    color: #efa758
}

.sc_layouts_menu_nav > li.current-menu-ancestor > a {
    font-weight: 500
}

.sc_layouts_menu_nav > li ul:not(.sc_item_filters_tabs) {
    position: absolute;
    display: none;
    z-index: 10000;
    width: 13em;
    padding: 1.4em 0;
    text-align: left;
    background-color: #f0f0f0;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .2);
    -ms-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .2);
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .2)
}

.sc_layouts_menu_nav > li li > a {
    padding: .6em 1.5em;
    color: #333
}

.sc_layouts_menu_nav > li li.current-menu-item > a, .sc_layouts_menu_nav > li li > a:hover {
    color: #efa758
}

.sc_layouts_menu_nav > li li.current-menu-item:before {
    color: #efa758
}

.sc_layouts_menu_nav > li > ul {
    top: 4em;
    left: 0
}

.sc_layouts_menu_nav > li:last-child > ul {
    left: auto;
    right: 0
}

.sc_layouts_menu_nav > li > ul ul {
    left: 13em;
    top: -1.4em;
    margin: 0 0 0 2px;
    background-color: #f0f0f0
}

.sc_inner_width_none > .skocak-widget-wrap {
    width: 100%
}


.sc_blogger_columns_wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.sc_blogger_columns_wrap .sc_blogger_item, .sc_blogger_slider .sc_blogger_item {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.sc_blogger_slider .sc_blogger_item {
    margin-bottom: 0 !important
}

.sc_blogger .sc_blogger_item_align_left .sc_blogger_item_content {
    text-align: left
}

.sc_blogger .sc_blogger_item_align_center .sc_blogger_item_content {
    text-align: center
}

.sc_blogger .sc_blogger_item_title {
    margin: 0
}

.sc_blogger .sc_blogger_item_featured {
    margin-bottom: 0
}

.sc_blogger .sc_blogger_item_featured[class*=hover_] {
    display: block
}

.sc_blogger .sc_blogger_item_featured .post_meta_label {
    display: none
}

.sc_blogger .post_meta_categories {
    margin: 0 0 .5em
}

.sc_blogger .sc_blogger_item_meta + .sc_blogger_item_excerpt, .sc_blogger .sc_blogger_item_title + .sc_blogger_item_excerpt {
    margin-top: 1em
}

.sc_blogger .sc_blogger_item_excerpt + .sc_blogger_item_button {
    margin-top: 1em
}

.sc_blogger_item_default.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content {
    margin-top: 1em
}

.sc_blogger_content .sc_blogger_item_wide {
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: 1px solid #ddd
}

.sc_blogger_content .sc_blogger_item_wide:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none
}

.sc_blogger_item_wide {
    overflow: hidden;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.sc_blogger_item_wide .sc_blogger_item_body {
    width: 50%
}

.sc_blogger_item_wide .sc_blogger_item_content {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.sc_blogger_item_wide .sc_blogger_item_content {
    padding-left: 2em
}

.sc_blogger_item_wide.sc_blogger_item_with_image .sc_blogger_item_body {
    width: 70%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.sc_blogger_item_wide.sc_blogger_item_with_image .sc_blogger_item_featured {
    width: 40%
}

.sc_blogger_item_wide.sc_blogger_item_with_image .sc_blogger_item_content {
    width: 60%
}

.sc_blogger_item_wide.sc_blogger_item_with_image.sc_blogger_item_image_position_left .sc_blogger_item_content {
    padding-left: 2em
}

.sc_blogger_content .sc_blogger_item_list {
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid #ddd
}

.sc_blogger_content .sc_blogger_item_list:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none
}

.sc_blogger_item_list .sc_blogger_item_meta .post_meta_number {
    margin-right: 0
}

.sc_blogger_item_list .sc_blogger_item_meta .post_meta_label {
    display: none
}

.sc_blogger_item_list.sc_blogger_item_with_numbers.sc_blogger_item_with_image .sc_blogger_item_featured {
    overflow: visible
}

.sc_blogger_item_list.sc_blogger_item_with_image.sc_blogger_item_image_position_left .sc_blogger_item_body {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.sc_blogger_item_list.sc_blogger_item_with_image.sc_blogger_item_image_position_left .sc_blogger_item_content {
    padding-left: 1em;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.widget_instagram {
    overflow: hidden
}

.widget_instagram_images {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.widget_instagram_images_item_wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin: 0;
    width: 25%;
    height: auto;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.widget_instagram_images_item {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center
}

.widget_instagram_images img {
    width: 100%;
    height: auto
}

.widget_instagram_images_item:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity .3s ease;
    -ms-transition: opacity .3s ease;
    transition: opacity .3s ease
}

.widget_instagram_images_item:hover:before {
    opacity: 1
}

.widget_instagram_images_item .widget_instagram_images_item_counters {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: -100px;
    opacity: 0;
    color: #fff;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 11px
}

.widget_instagram_images_item:hover .widget_instagram_images_item_counters {
    margin-top: 0;
    opacity: 1
}

.widget_instagram_images_item [class*=widget_instagram_images_item_counter_] {
    display: inline-block;
    vertical-align: middle;
    margin-right: .75em;
    line-height: 1.5em
}

.widget_instagram_images_item [class*=widget_instagram_images_item_counter_]:last-child {
    margin-right: 0
}

.widget_instagram_images_item [class*=widget_instagram_images_item_counter_]:before {
    display: inline-block;
    vertical-align: middle;
    margin-right: .25em
}

:root {
    --global-kb-font-size-sm: clamp(0.8rem, 0.73rem + 0.217vw, 0.9rem);
    --global-kb-font-size-md: clamp(1.1rem, 0.995rem + 0.326vw, 1.25rem);
    --global-kb-font-size-lg: clamp(1.75rem, 1.576rem + 0.543vw, 2rem);
    --global-kb-font-size-xl: clamp(2.25rem, 1.728rem + 1.63vw, 3rem);
    --global-kb-font-size-xxl: clamp(2.5rem, 1.456rem + 3.26vw, 4rem);
    --global-kb-font-size-xxxl: clamp(2.75rem, 0.489rem + 7.065vw, 6rem)
}

:root {
    --global-palette1: #3182CE;
    --global-palette2: #2B6CB0;
    --global-palette3: #1A202C;
    --global-palette4: #2D3748;
    --global-palette5: #4A5568;
    --global-palette6: #718096;
    --global-palette7: #EDF2F7;
    --global-palette8: #F7FAFC;
    --global-palette9: #ffffff
}

a, article, aside, body, button, del, div, h2, h3, h4, h5, h6, html, ins, li, section, span, ul {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    font-weight: inherit;
    font-style: inherit;
    outline: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word
}

button {
    border: 0
}

body, html {
    margin: 0;
    padding: 0;
    width: 100%
}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    overflow-y: scroll !important;
    overflow-x: hidden;
    height: 100%
}

:root {
    --theme-var-koef_narrow: 0.7412;
    --theme-var-menu_side: 6rem;
    --theme-var-rad50: calc(50% * var(--theme-var-rad_koef));
    --theme-var-rad1em: calc(1em * var(--theme-var-rad_koef));
    --theme-var-rad4: calc(4px * var(--theme-var-rad_koef));
    --theme-var-rad3: calc(3px * var(--theme-var-rad_koef));
    --theme-var-rad2: calc(2px * var(--theme-var-rad_koef));
    --theme-var-page: var(--theme-var-page_width);
    --theme-var-sidebar_width_max: 500px;
    --theme-var-sidebar_width_min: 150px;
    --theme-var-sidebar_gap_width_max: 100px;
    --theme-var-sidebar_gap_width_min: 0px;
    --theme-var-sidebar: clamp(var(--theme-var-sidebar_width_min, 150px), calc(var(--theme-var-page) * var(--theme-var-sidebar_prc) * var(--theme-var-sidebar_proportional) + var(--theme-var-sidebar_width) * (1 - var(--theme-var-sidebar_proportional))), var(--theme-var-sidebar_width_max, 500px));
    --theme-var-sidebar_gap: clamp(var(--theme-var-sidebar_gap_width_min, 0px), calc(var(--theme-var-page) * var(--theme-var-sidebar_gap_prc) * var(--theme-var-sidebar_proportional) + var(--theme-var-sidebar_gap_width) * (1 - var(--theme-var-sidebar_proportional))), var(--theme-var-sidebar_gap_width_max, 100px));
    --theme-var-sidebar_and_gap: calc(var(--theme-var-sidebar) + var(--theme-var-sidebar_gap));
    --theme-var-content: calc(var(--theme-var-page) - var(--theme-var-sidebar) - var(--theme-var-sidebar_gap));
    --theme-var-content_narrow: calc(var(--theme-var-content) * var(--theme-var-koef_narrow));
    --theme-var-padding_narrow: calc(var(--theme-var-content) * (1 - var(--theme-var-koef_narrow)));
    --theme-var-page_column: calc((var(--theme-var-page_narrow_width) - (11 * var(--theme-var-grid_gap))) / 12);
    --theme-var-page_column_and_gap: calc(var(--theme-var-page_column) + var(--theme-var-grid_gap));
    --theme-var-content_narrow: calc(var(--theme-var-page_column) * 6 + 5 * var(--theme-var-grid_gap));
    --theme-var-padding_narrow: calc((var(--theme-var-page_narrow_width) - var(--theme-var-content_narrow)) / 2);
    --fixed-rows-height: 0px
}

.alter_bg {
    background-color: var(--theme-color-alter_bg_color) !important
}

html {
    font-size: 14px
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-touch-action: auto;
    touch-action: auto
}

body, body[class*=scheme_] {
    background-color: var(--theme-color-bg_color)
}

[class*=scheme_], body[class*=scheme_] {
    color: var(--theme-color-text)
}

article, aside, header, nav, section {
    display: block
}

ul:last-child {
    margin-bottom: 0
}



li > ul {
    margin-bottom: 0
}

ul {
    padding-left: 1.5em
}

a {
    background: 0 0;
    color: var(--theme-color-text_link, #ee9982);
    text-decoration: none
}

a:hover {
    color: var(--theme-color-text_hover, #e88368)
}

:active, :focus, a, a:active, a:focus, a:hover {
    outline: 0
}

a img {
    border: none
}

a, button {
    -webkit-transition: color .3s ease, background-color .3s ease, border-color .3s ease;
    -ms-transition: color .3s ease, background-color .3s ease, border-color .3s ease;
    -o-transition: color .3s ease, background-color .3s ease, border-color .3s ease;
    transition: color .3s ease, background-color .3s ease, border-color .3s ease
}

h3 > a, h4 > a, h5 > a, h6 > a {
    display: block
}

h2, h3, h3 a, h4, h4 a, h5, h5 a, h6, h6 a, li a {
    color: var(--theme-color-text_dark)
}

h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, li a:hover {
    color: var(--theme-color-text_link)
}

i {
    font-style: italic
}

ins {
    background-color: transparent;
    text-decoration: none
}

[class*=" icon-"]:before, [class^=icon-]:before {
    font-size: inherit;
    line-height: inherit !important;
    font-weight: inherit;
    font-style: inherit;
    display: inline-block;
    margin: 0;
    width: auto
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top
}

button {
    font-family: inherit;
    font-size: 1em;
    margin: 0;
    vertical-align: baseline
}

button {
    overflow: visible
}

.sc_button:not(.sc_button_simple) {
    -webkit-appearance: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    font-size: var(--theme-font-button_font-size);
    line-height: var(--theme-font-button_line-height);
    font-weight: var(--theme-font-button_font-weight);
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: var(--theme-var-rad);
    -ms-border-radius: var(--theme-var-rad);
    border-radius: var(--theme-var-rad);
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: .12em;
    height: 51px;
    max-width: 100%;
    border-width: 2px !important;
    border-style: solid;
    padding: 14px 43px 14px 44px
}

.sc_button:not(.sc_button_simple):not([class*=color_style_]) {
    color: var(--theme-color-inverse_link);
    border-color: var(--theme-color-text_link);
    background-color: var(--theme-color-text_link);
    -webkit-box-shadow: 0 5px 12px var(--theme-color-text_link_01);
    box-shadow: 0 5px 12px var(--theme-color-text_link_01)
}

.sc_button_icon, .sc_button_text {
    display: inline-block;
    vertical-align: bottom;
    white-space: normal;
    text-decoration: inherit
}

.sc_button_icon {
    font-size: 18px;
    height: 19px;
    line-height: 19px;
    width: 19px;
    text-align: left
}

.sc_button_icon_left .sc_button_icon {
    margin-right: 8px;
    text-align: right
}

.sc_button.sc_button_size_small:not(.sc_button_simple):not(.sc_button_rounded) {
    padding: 11px 27px 11px 28px;
    font-size: 12px;
    height: 44px;
    line-height: 18px
}

.sc_button.sc_button_size_small:not(.sc_button_simple):not(.sc_button_rounded) .sc_button_icon {
    line-height: 18px;
    height: 18px
}

.sc_button.sc_button_simple {
    font-size: 1rem;
    line-height: 1.25em;
    font-weight: 600;
    background: 0 0 !important;
    border: none !important;
    letter-spacing: .06em;
    text-transform: none;
    -webkit-box-shadow: none !important;
    -ms-box-shadow: none !important;
    box-shadow: none !important;
    border: none;
    border-radius: 0;
    padding: 0;
    width: auto
}

.sc_button.sc_button_simple:after, .sc_button.sc_button_simple:before {
    display: none !important
}

.sc_button.color_style_3:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image), .sc_button_default.color_style_3 {
    color: var(--theme-color-text_dark);
    border-color: var(--theme-color-bd_color);
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.sc_button.hover_style_1:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):focus, .sc_button.hover_style_1:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):hover, .sc_button_default.hover_style_1:focus, .sc_button_default.hover_style_1:hover {
    color: var(--theme-color-extra_dark);
    border-color: var(--theme-color-extra_bg_hover);
    background-color: var(--theme-color-extra_bg_hover);
    -webkit-box-shadow: 0 5px 12px var(--theme-color-extra_bg_hover_01);
    box-shadow: 0 5px 12px var(--theme-color-extra_bg_hover_01)
}

.sc_button.hover_style_5:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):focus, .sc_button.hover_style_5:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):hover, .sc_button_default.hover_style_5:focus, .sc_button_default.hover_style_5:hover {
    color: var(--theme-color-text_dark);
    border-color: var(--theme-color-text_dark);
    background-color: var(--theme-color-bg_color);
    -webkit-box-shadow: none;
    box-shadow: none
}

.page_content_wrap .sc_button_wrap .sc_button_default {
    position: relative;
    -webkit-animation: animation_back_down .3s ease forwards;
    animation: animation_back_down .3s ease forwards
}

.page_content_wrap .sc_button_wrap .sc_button_default:hover {
    -webkit-animation: animation_jump_up .3s ease forwards;
    animation: animation_jump_up .3s ease forwards
}

.sc_button.sc_button_simple:not(.sc_button_bg_image) {
    color: var(--theme-color-text_dark)
}

.sc_button.sc_button_simple:not(.sc_button_bg_image).color_style_1 {
    color: var(--theme-color-text_dark)
}

.sc_button.sc_button_simple:not(.sc_button_bg_image).color_style_1:focus, .sc_button.sc_button_simple:not(.sc_button_bg_image).color_style_1:hover {
    color: var(--theme-color-text_link)
}

button:focus {
    outline: 0 !important
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    margin: 0 !important;
    padding: 0 !important;
    position: absolute !important
}

.screen-reader-text:active, .screen-reader-text:focus, .screen-reader-text:hover {
    width: auto;
    height: auto;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6);
    -ms-box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6);
    font-size: .8em;
    line-height: normal;
    background-color: #f1f1f1;
    border-radius: 3px;
    clip: auto !important;
    color: #21759b;
    display: block;
    left: 5px;
    padding: 1em 1.5em;
    text-decoration: none;
    top: 5px;
    z-index: 100000
}

.page_wrap {
    min-height: 100vh;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.content_wrap, .page_wrap {
    margin: 0 auto
}

.page_content_wrap {
    padding-bottom: 5.4rem;
    padding-top: 5.4rem
}

.content_wrap {
    margin: 0 auto;
    max-width: 100%;
    width: var(--theme-var-page)
}

.content_wrap:after {
    clear: both;
    width: 100%;
    height: 0;
    display: block;
    content: " "
}

.content {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.page_content_wrap .content_wrap {
    position: relative
}

.page_content_wrap {
    background-color: var(--theme-color-bg_color)
}

.sidebar_hide [class*=content_wrap] > .content {
    margin-left: auto;
    margin-right: auto
}

.top_panel {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: .02px 0;
    position: relative;
    z-index: 8000
}

.top_panel {
    background-color: var(--theme-color-bg_color);
    color: var(--theme-color-text)
}

.sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    margin-right: 10px
}

header .skocak-widget-trx_sc_button {
    line-height: 1.571428rem
}

header .sc_button.sc_button_size_small {
    font-size: 12px;
    padding: 11px 24px
}

header .sc_button.sc_button_size_small.sc_button_icon_left .sc_button_icon {
    margin-right: 3px
}

.sc_layouts_cart {
    margin-right: 8px
}

.sc_layouts_cart_icon {
    font-size: 1.3em !important
}

.sc_layouts_cart_icon:before {
    content: '\e9a5';
    font-family: fontello
}

.sc_layouts_cart_widget {
    background-color: var(--theme-color-extra_bg_color);
    border: none;
    color: var(--theme-color-extra_dark);
    padding: 2.6rem 2.142rem 2.9rem;
    right: -4.7em;
    top: 3.6em;
    width: 330px
}

.sc_layouts_cart_widget:after {
    display: none
}

.sc_layouts_item_icon {
    color: var(--theme-color-text_dark);
    font-size: inherit;
    line-height: inherit;
    vertical-align: baseline;
    font-weight: 400
}

.sc_layouts_row_fixed {
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important
}

.sc_layouts_row_type_compact {
    padding: .15em 0
}

.sc_layouts_row_type_compact .sc_layouts_menu_nav > li > a {
    letter-spacing: .1em
}

.sc_layouts_row_type_compact .sc_layouts_item {
    color: var(--theme-color-text)
}

.sc_layouts_row_type_compact .sc_layouts_item a:not(.sc_button):not(.button) {
    color: var(--theme-color-text_dark)
}

.sc_layouts_row_type_compact .sc_layouts_item a:focus .sc_layouts_item_icon, .sc_layouts_row_type_compact .sc_layouts_item a:hover .sc_layouts_item_icon, .sc_layouts_row_type_compact .sc_layouts_item a:not(.sc_button):not(.button):focus, .sc_layouts_row_type_compact .sc_layouts_item a:not(.sc_button):not(.button):hover {
    color: var(--theme-color-text_link)
}

.sc_layouts_row_type_compact .sc_layouts_item_icon {
    color: var(--theme-color-text_dark)
}

.sc_layouts_row_type_compact .sc_layouts_item_icon.sc_layouts_cart_icon:hover {
    color: var(--theme-color-text_link)
}

.sc_layouts_logo {
    display: block
}

.sc_layouts_menu_nav > li ul:not(.sc_item_filters_tabs):not(.sc_layouts_submenu) {
    width: 16.2rem;
    padding: 1em 0
}

.sc_layouts_menu_nav > li > a {
    padding: 1.05em;
    color: var(--theme-color-text_dark)
}

.sc_layouts_menu_nav > li.current-menu-ancestor > a, .sc_layouts_menu_nav > li > a:hover {
    color: var(--theme-color-text_link) !important
}

.sc_layouts_menu_nav > li > ul {
    left: 0;
    top: 4.4em
}

.sc_layouts_menu_nav > li > ul ul {
    left: 15.3em;
    margin: 0 0 0 2px;
    top: -1em
}

.sc_layouts_menu_nav > li ul:not(.sc_item_filters_tabs), .sc_layouts_menu_nav > li > ul ul:not(.sc_item_filters_tabs) {
    -webkit-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
    background-color: var(--theme-color-extra_bg_color)
}

.sc_layouts_menu_nav > li > ul ul.sc_item_filters_tabs {
    background-color: transparent
}

.sc_layouts_menu_nav > li li > a {
    color: var(--theme-color-extra_text) !important;
    padding: .7em 1.55em
}

.sc_layouts_menu_nav > li li > a:hover {
    color: var(--theme-color-extra_dark) !important
}

.sc_layouts_menu_nav > li li.current-menu-item > a {
    color: var(--theme-color-extra_dark) !important
}

.sc_layouts_menu_nav > li li.current-menu-item:before {
    color: var(--theme-color-extra_dark) !important
}

.menu_mobile .menu_mobile_nav_area > ul > li ul > li > a span:not(.open_child_menu), .sc_item_filters_tabs > li > a, .sc_layouts_menu_dir_horizontal .sc_layouts_menu_nav > li ul > li > a span {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(currentColor));
    background-image: -webkit-linear-gradient(left, currentColor 0, currentColor 100%);
    background-image: -o-linear-gradient(left, currentColor 0, currentColor 100%);
    background-image: linear-gradient(to right, currentColor 0, currentColor 100%);
    background-repeat: no-repeat;
    background-size: 0 1px
}

.sc_item_filters_tabs > li > a {
    background-position: 0 100%;
    -webkit-transition: background-size .4s cubic-bezier(.25, .4, .25, 1), color .3s ease;
    -o-transition: background-size .4s cubic-bezier(.25, .4, .25, 1), color .3s ease;
    transition: background-size .4s cubic-bezier(.25, .4, .25, 1), color .3s ease;
    display: inline-block !important
}

.menu_mobile .menu_mobile_nav_area > ul > li ul > li > a span:not(.open_child_menu), .sc_layouts_menu_dir_horizontal .sc_layouts_menu_nav > li ul > li > a span {
    -webkit-transition: background-size .4s cubic-bezier(.25, .4, .25, 1);
    -o-transition: background-size .4s cubic-bezier(.25, .4, .25, 1);
    transition: background-size .4s cubic-bezier(.25, .4, .25, 1);
    display: inline !important
}

.menu_mobile .menu_mobile_nav_area > ul > li ul > li > a:hover span:not(.open_child_menu), .sc_item_filters_tabs > li > a:hover, .sc_layouts_menu_dir_horizontal .sc_layouts_menu_nav > li ul > li > a:hover span {
    -webkit-background-size: 100% 1px;
    background-size: 100% 1px
}

.menu_mobile .menu_mobile_nav_area > ul > li ul > li > a span:not(.open_child_menu), .sc_layouts_menu_dir_horizontal .sc_layouts_menu_nav > li ul > li > a span:not(.open_child_menu) {
    background-position: 0 100%
}

.menu_mobile .menu_mobile_nav_area > ul > li ul > li.current-menu-item > a span:not(.open_child_menu), .sc_layouts_menu_dir_horizontal .sc_layouts_menu_nav > li ul > li.current-menu-item > a span:not(.open_child_menu) {
    background-size: 100% 1px !important
}

.sc_item_filters_tabs > li > a {
    background-position: 0 100%
}

.menu_mobile_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    display: none !important;
    z-index: 100000
}

.menu_mobile {
    width: 100%;
    height: 0;
    position: fixed;
    z-index: 100002;
    top: 0;
    left: 0;
    -webkit-transition: height .3s ease;
    -ms-transition: height .3s ease;
    -o-transition: height .3s ease;
    transition: height .3s ease
}

.menu_mobile_inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    background-color: white;
    z-index: 999;
    overflow: hidden;
}

.mobile-menu-content {
    background-color: white;
    height: 100%;
    width: 100%;
    z-index: 999;
}

.menu_mobile .sc_layouts_logo {
    display: block;
    max-width: 280px;
    font-size: 1em !important;
    margin: 3.6em auto 0;
    vertical-align: top
}

.menu_mobile .menu_mobile_nav_area {
    position: absolute;
    z-index: 1;
    top: 48%;
    left: 0;
    max-width: 280px;
    height: auto;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-height: 49%;
    overflow-y: scroll;
    padding-right: 20px;
    line-height: 1.5em;
    font-size: 1.2857rem
}

.menu_mobile .menu_mobile_nav_area ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: auto
}

.menu_mobile .menu_mobile_nav_area li {
    margin-top: 0;
    margin-bottom: 0;
    width: auto
}

.menu_mobile .menu_mobile_nav_area > ul > li > ul {
    font-size: 1.0715rem;
    line-height: 1.5em
}

.menu_mobile .menu_mobile_nav_area > ul > li > ul a {
    padding: .25em 1em
}

.menu_mobile .menu_mobile_nav_area .menu-item > a {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    max-width: 280px;
    position: relative;
    text-transform: none;
    color: var(--theme-color-text);
    letter-spacing: 0
}

.menu_mobile .menu_mobile_nav_area .current-menu-ancestor > a, .menu_mobile .menu_mobile_nav_area .menu-item > a:hover {
    color: var(--theme-color-text_dark)
}

.menu_mobile .menu_mobile_nav_area > ul {
    width: 100%;
    line-height: 1.5em;
    font-size: 1.2857rem
}

.menu_mobile .menu_mobile_nav_area > ul > .menu-item > a {
    font-weight: 700;
    color: var(--theme-color-text);
    padding: .65em 0;
    letter-spacing: -.01em
}

.menu_mobile .menu_mobile_nav_area .menu-item.current-menu-item > a {
    color: var(--theme-color-text_dark)
}

.menu_mobile .social_item {
    display: inline-block;
    margin: 1em 2em 0 0
}

.menu_mobile .social_item:last-child {
    margin-right: 0
}

.menu_mobile .social_item .social_icon {
    width: 1em;
    height: 1em;
    line-height: 1em;
    text-align: center;
    display: block;
    font-size: 1em
}

.sc_blogger .sc_blogger_item .post_featured + .sc_blogger_item_content .sc_blogger_item_title a, .sc_blogger .sc_blogger_item.sc_blogger_item_default .sc_blogger_item_content .sc_blogger_item_title a {
    color: var(--theme-color-text_dark) !important
}

.sc_blogger .sc_blogger_item [class*=post_info_] .sc_blogger_item_title, .sc_blogger .sc_blogger_item [class*=post_info_] .sc_blogger_item_title a {
    color: var(--theme-color-extra_dark) !important
}

.sc_blogger .sc_blogger_item .post_featured.post_featured_bg .featured_bg_wrapper {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden
}

.sc_blogger .sc_blogger_item .post_featured.post_featured_bg .featured_bg {
    -webkit-transition: -webkit-transform 1s ease;
    -ms-transition: -ms-transform 1s ease;
    transition: -webkit-transform 1s ease;
    -o-transition: transform 1s ease;
    transition: transform 1s ease;
    transition: transform 1s ease, -webkit-transform 1s ease;
    -webkit-transform: scale(1.02, 1.02);
    -ms-transform: scale(1.02, 1.02);
    transform: scale(1.02, 1.02)
}

.sc_blogger .sc_blogger_item .post_featured > a {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.sc_blogger .sc_blogger_item:hover .post_featured.post_featured_bg.hover_inherit .featured_bg {
    -webkit-transform: scale(1.09, 1.09);
    -ms-transform: scale(1.09, 1.09);
    transform: scale(1.09, 1.09)
}

.sc_blogger .sc_blogger_item .post_featured + .sc_blogger_item_content .sc_blogger_item_title a, .sc_blogger .sc_blogger_item .sc_item_featured [class*=post_info_] .entry-title a, .sc_blogger .sc_blogger_item.sc_blogger_item_default .sc_blogger_item_content .sc_blogger_item_title a {
    display: inline;
    padding: 0;
    background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(currentColor));
    background-image: -webkit-linear-gradient(left, currentColor 0, currentColor 100%);
    background-image: -o-linear-gradient(left, currentColor 0, currentColor 100%);
    background-image: linear-gradient(to right, currentColor 0, currentColor 100%);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 2px;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background-size .8s cubic-bezier(.25, .8, .25, 1);
    -o-transition: background-size .8s cubic-bezier(.25, .8, .25, 1);
    transition: background-size .8s cubic-bezier(.25, .8, .25, 1)
}

.post_meta, .post_meta_item, .post_meta_item a, .post_meta_item:after, .post_meta_item:hover:after {
    color: var(--theme-color-text_light)
}

.post_date a:focus, .post_date a:hover, .post_meta_item a:focus, .post_meta_item a:hover, a.post_meta_item:focus, a.post_meta_item:hover {
    color: var(--theme-color-text_hover)
}

.post_meta .post_meta_item {
    -webkit-transition: color .3s ease;
    -ms-transition: color .3s ease;
    -o-transition: color .3s ease;
    transition: color .3s ease;
    display: inline-block;
    font-size: inherit;
    margin-left: 0;
    vertical-align: middle
}

.post_meta .post_meta_item:after {
    content: '';
    display: inline-block;
    margin: 0 .55em;
    vertical-align: baseline
}

.post_meta .post_meta_item:last-child:after {
    display: none
}

.post_meta .post_meta_item:before {
    margin-right: .3em
}

.post_meta .post_meta_item .post_meta_number + .post_meta_label {
    margin-left: .3em;
    display: none
}

.post_meta .post_author {
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.post_meta .post_author .post_author_by {
    margin-right: .3em
}

.post_meta .post_author .post_author_name {
    -webkit-transition: color .3s ease;
    -ms-transition: color .3s ease;
    -o-transition: color .3s ease;
    transition: color .3s ease;
    color: var(--theme-color-text_dark);
    display: inline-block;
    font-weight: 600
}

.post_meta .post_author:hover .post_author_name {
    color: var(--theme-color-text_hover)
}

.post_meta_item.post_categories {
    color: var(--theme-color-text_link);
    font-weight: 600;
    letter-spacing: .06em;
    vertical-align: baseline
}

.post_meta_item.post_categories > a {
    margin-right: .5em
}

.post_meta_item.post_categories > a:last-child {
    margin-right: 0
}

.post_meta_item.post_categories a {
    color: var(--theme-color-text_link)
}

.post_meta_item.post_categories a:hover {
    color: var(--theme-color-text_dark)
}

.post_meta_item.icon-comment-light:before {
    content: '\E9B2'
}

.page_content_wrap {
    position: relative
}

.post_featured {
    margin-bottom: 1.95em;
    overflow: hidden;
    position: relative
}

.post_featured_bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}

.post_featured_bg:before {
    width: 0;
    height: 0;
    content: ' ';
    display: inline-block;
    margin-left: -.3em;
    padding-top: 56.25%;
    vertical-align: top
}

.featured_bg_wrapper, .featured_bg_wrapper .featured_bg {
    height: 100%
}

.featured_bg_wrapper .featured_bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center
}

.post_featured .mask {
    background-color: rgba(0, 0, 0, .5)
}

.post_featured.with_thumb {
    position: relative
}

.post_item_single .post_meta_item > a:hover, .post_item_single a.post_meta_item:hover {
    color: var(--theme-color-text_hover)
}

.post_item_single::after {
    clear: both;
    content: ' ';
    display: block;
    height: 0;
    width: 100%
}

.skocak-sidebar .skocak-widget + .skocak-widget {
    border-top: 1px solid var(--theme-color-alter_bd_color);
    margin-top: 4.25rem;
    padding-top: 3.85rem
}

.content .skocak-widget .widget .widget_title, .skocak-sidebar .sc_item_title, .skocak-sidebar .widget .widget_title {
    margin-bottom: 1.3em;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase
}

.skocak-sidebar .sc_item_title_wrap + .sc_item_content {
    margin-top: 0
}

.widget .widget_title {
    margin-bottom: 1em;
    margin-top: 0
}

.skocak-sidebar .sc_item_title:after, .skocak-sidebar .widget_title:after {
    display: none !important
}

#sb_instagram .sbi_photo {
    margin: 0 -1px
}

.pk-icon-facebook:before {
    content: "\E9A8";
    font-family: fontello
}

.pk-icon-twitter:before {
    font-size: 89%
}

.pk-icon-youtube:before {
    content: "\E9AB";
    font-family: fontello
}

.pk-icon-instagram:before {
    font-size: .8em;
    line-height: 1.2em
}

.pk-social-links-wrap .pk-social-links-items {
    margin: 0 -9px 0 0
}

.pk-social-links-wrap .pk-social-links-item {
    padding: 0 9px 0 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.pk-social-links-wrap .pk-social-links-item a .pk-icon {
    -webkit-animation: animation_back_down .3s ease-out forwards;
    animation: animation_back_down .3s ease-out forwards;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
}

.pk-social-links-wrap .pk-social-links-item a:hover .pk-icon {
    -webkit-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation: animation_jump_up .3s ease-out forwards;
    animation: animation_jump_up .3s ease-out forwards
}

.pk-social-links-wrap.pk-social-links-template-columns .pk-social-links-link {
    padding: 0 !important
}

.pk-social-links-wrap .pk-font-secondary {
    font-size: 1.214rem;
    line-height: 1.5em;
    font-weight: 400;
    color: var(--theme-color-text_dark)
}

.pk-social-links-wrap .pk-font-secondary {
    margin-top: .55rem
}

.pk-social-links-wrap .pk-social-links-label {
    font-size: 13px;
    line-height: 1.33em;
    font-weight: 400;
    color: var(--theme-color-text);
    letter-spacing: 0;
    margin-top: 0
}

.pk-social-links-wrap .pk-social-links-count {
    font-weight: 600;
}

.pk-social-links-wrap.pk-social-links-scheme-default .pk-social-links-icon {
    width: 1.15em;
    height: 1.15em;
    line-height: 1.15em;
    text-align: center;
    font-size: 1.785rem
}

.pk-social-links-wrap.pk-social-links-scheme-rounded .pk-social-links-icon {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    width: 4.572rem;
    height: 4.572rem;
    line-height: 4.4rem;
    background-color: var(--theme-color-bg_color);
    border: 1px solid var(--theme-color-bd_color);
    font-size: 1.142rem
}

.pk-social-links-wrap .pk-social-links-twitter .pk-social-links-link {
    color: #00aced
}

.pk-social-links-wrap .pk-social-links-twitter .pk-social-links-link:hover .pk-social-links-icon {
    color: #21c2ff
}

.pk-social-links-wrap .pk-social-links-facebook .pk-social-links-link {
    color: #3b5998
}

.pk-social-links-wrap .pk-social-links-facebook .pk-social-links-link:hover .pk-social-links-icon {
    color: #4c70ba
}

.pk-social-links-wrap .pk-social-links-instagram .pk-social-links-link {
    color: #c862dc
}

.pk-social-links-wrap .pk-social-links-instagram .pk-social-links-link:hover .pk-social-links-icon {
    color: #cc6edf
}

.pk-social-links-wrap .pk-social-links-youtube .pk-social-links-link {
    color: red
}

.pk-social-links-wrap .pk-social-links-youtube .pk-social-links-link:hover .pk-social-links-icon {
    color: #f33
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-items {
    display: block;
    margin: -.3rem
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-item {
    display: inline-block;
    padding: .3rem
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-link {
    background-color: transparent !important;
    color: var(--theme-color-text_dark) !important;
    padding: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-link:hover {
    background-color: transparent;
    color: var(--theme-color-text_link) !important
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-icon {
    font-size: 1.3rem;
    line-height: 19px;
    height: 20px;
    text-align: center
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-item:first-child .pk-social-links-icon {
    text-align: left
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-link .pk-social-links-count {
    font-size: 1rem;
    font-weight: 400;
    color: var(--theme-color-text);
    display: block;
    opacity: 1;
    padding: 0 .4rem 0 .2rem;
    margin-top: .2em
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-link:hover .pk-social-links-count {
    color: var(--theme-color-text)
}

.post_featured[class*=hover_] {
    position: relative;
    -webkit-transition: all .35s ease-in-out;
    -ms-transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out
}

.post_featured[class*=hover_], .post_featured[class*=hover_] * {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.post_featured .mask {
    content: ' ';
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease
}

.post_featured.hover_shop {
    position: relative;
    display: block
}

.post_featured.hover_shop .icons {
    position: absolute;
    z-index: 1002;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    pointer-events: none
}

.post_featured.hover_shop .icons a {
    position: relative;
    top: 20px;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    pointer-events: all;
    font-size: 1.2632em !important;
    padding: 0 !important;
    letter-spacing: 0 !important;
    cursor: pointer;
    text-align: center;
    color: #e6b088 !important;
    background-color: var(--theme-color-extra_dark) !important;
    opacity: 0;
    border: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    width: 51px !important;
    height: 51px !important;
    line-height: 51px !important;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .5s cubic-bezier(.6, -.28, .735, .045) 0s;
    -ms-transition: all .5s cubic-bezier(.6, -.28, .735, .045) 0s;
    -o-transition: all .5s cubic-bezier(.6, -.28, .735, .045) 0s;
    transition: all .5s cubic-bezier(.6, -.28, .735, .045) 0s
}

.post_featured.hover_shop .icons a:hover {
    color: var(--theme-color-extra_dark) !important;
    border-color: var(--theme-color-extra_hover) !important;
    background-color: var(--theme-color-extra_hover) !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.post_featured.hover_shop .icons a + a {
    margin-left: 9px;
    -webkit-transition-delay: .15s;
    -ms-transition-delay: .15s;
    -o-transition-delay: .15s;
    transition-delay: .15s
}

.post_featured.hover_shop .icons a.shop_link:before {
    font-family: fontello !important;
    font-weight: 400 !important;
    content: '\e9c8' !important;
    font-size: 1.3rem
}

.post_featured.hover_shop .icons a.shop_cart:before {
    font-family: fontello !important;
    font-weight: 400 !important;
    content: '\e9be' !important;
    font-size: 1.5rem
}

.post_featured.hover_shop .icons a.shop_cart:after {
    display: none !important
}

.post_featured.hover_shop .icons a:after, .post_featured.hover_shop .icons a:before {
    margin: 0 !important;
    padding: 0 !important
}

.post_featured.hover_shop:focus .icons a:nth-child(1), .post_featured.hover_shop:hover .icons a:nth-child(1) {
    -webkit-animation: animation_fade_in_up .3s ease forwards;
    animation: animation_fade_in_up .3s ease forwards;
    -webkit-animation-delay: 50ms;
    animation-delay: 50ms
}

.post_featured.hover_shop:focus .icons a:nth-child(2), .post_featured.hover_shop:hover .icons a:nth-child(2) {
    -webkit-animation: animation_fade_in_up .3s ease forwards;
    animation: animation_fade_in_up .3s ease forwards;
    -webkit-animation-delay: .1s;
    animation-delay: .1s
}

.post_featured.hover_shop:hover .icons a {
    -webkit-transition: all .4s;
    -ms-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s
}

.post_featured.hover_shop:hover .icons a + a {
    -webkit-transition-delay: .15s;
    -ms-transition-delay: .15s;
    -o-transition-delay: .15s;
    transition-delay: .15s
}

@media (min-width: 1280px) and (max-width: 1679px) {
    .sc_layouts_hide_on_notebook {
        display: block !important
    }
}

@media (min-width: 768px) and (max-width: 1279px) {
    .sc_layouts_hide_on_tablet {
        display: block !important
    }
}

@media (min-width: 1024px) and (max-width: 1679px) {
    .sc_layouts_hide_on_notebook {
        display: none !important
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .sc_layouts_hide_on_tablet {
        display: none !important
    }
}

.sc_button {
    -webkit-border-radius: var(--theme-var-rad);
    -ms-border-radius: var(--theme-var-rad);
    border-radius: var(--theme-var-rad)
}

.sc_layouts_column .sc_layouts_item:not(.skocak-widget-divider):not(.skocak-widget-spacer) {
    margin-right: 1.7em
}

.sc_layouts_column_align_center .sc_layouts_item:not(.skocak-widget-divider):not(.skocak-widget-spacer) {
    margin-left: .75em;
    margin-right: .75em
}

.sc_layouts_column_align_right .sc_layouts_item:not(.skocak-widget-divider):not(.skocak-widget-spacer) {
    margin-left: 1.7em;
    margin-right: 0
}

.sc_item_featured [class*=post_info_] {
    background-color: transparent;
    color: var(--theme-color-extra_text);
    max-width: 100%;
    width: 100%;
    padding: 29px 2.142rem 25px
}

.sc_item_featured:hover [class*=post_info_] {
    background-color: transparent
}

.sc_item_featured [class*=post_info_] a {
    color: var(--theme-color-extra_dark)
}

.sc_item_featured [class*=post_info_] a:focus, .sc_item_featured [class*=post_info_] a:hover {
    color: var(--theme-color-extra_hover)
}

.sc_item_featured [class*=post_info_] .post_meta a {
    color: var(--theme-color-extra_dark)
}

.sc_item_featured [class*=post_info_] .post_meta a:hover {
    color: var(--theme-color-extra_hover)
}

.sc_item_featured [class*=post_info_] .post_meta_item.post_categories {
    margin-top: -6px
}

.sc_item_featured [class*=post_info_] .post_meta_item.post_categories a {
    margin-top: 6px;
    line-height: 14px;
    display: inline-block
}

.sc_item_featured [class*=post_info_] .post_meta_item.post_categories:after {
    margin: 0 3px
}

.sc_item_featured [class*=post_info_] .post_meta_categories a {
    color: var(--theme-color-extra_link)
}

.sc_item_featured [class*=post_info_] .post_meta_categories a:hover {
    color: var(--theme-color-extra_hover)
}

.sc_item_featured [class*=post_info_] .post_meta + .entry-title {
    margin-top: 0
}

.slider_outer_pagination_pos_bottom .sc_item_featured .post_info_bc {
    bottom: 1.5rem
}

.sc_item_featured.post_featured_bg[data-ratio="16:9"]:before {
    padding-top: 56.25%
}

.sc_item_featured.post_featured_bg[data-ratio="10:7"]:before {
    padding-top: 70%
}

.sc_item_featured.post_featured_bg[data-ratio="1:1"]:before {
    padding-top: 100%
}

.sc_item_featured.post_featured_bg[data-ratio="7:8"]:before {
    padding-top: 114.285%
}

.sc_item_featured.post_featured_bg[data-ratio="5:6"]:before {
    padding-top: 120%
}

.post_featured_bg .featured_bg {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}

.widget_instagram_images_item .widget_instagram_images_item_counters {
    display: none
}

.sc_item_subtitle_below {
    margin-top: .3em
}

.sc_item_subtitle {
    font-size: 1.142rem;
    font-style: normal;
    font-weight: 400;
    color: var(--theme-color-text)
}

.sc_item_title_wrap + .sc_item_content {
    margin-top: 2.1em
}

.sc_title .sc_item_title_wrap .sc_item_title {
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.sc_title .sc_item_title_wrap .sc_item_title_line_left, .sc_title .sc_item_title_wrap .sc_item_title_line_right {
    border: 1px solid var(--theme-color-bd_color);
    border-width: 1px 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 4px
}

.sc_title .sc_item_title_wrap.sc_align_center {
    display: block
}

.sc_title .sc_item_title_wrap.sc_align_center .sc_item_title {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.sc_title .sc_item_title_wrap.sc_align_center .sc_item_title_line_right {
    margin: 0 0 0 30px
}

.sc_title .sc_item_title_wrap.sc_align_center .sc_item_title_line_left {
    margin: 0 30px 0 0
}

.sc_title .sc_item_title_wrap.sc_align_center + .sc_item_subtitle_below {
    margin-top: .1em
}

.sc_title .sc_item_title_wrap.sc_align_center.sc_position_below.sc_subtitle_present .sc_item_title {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.sc_title .sc_item_title_wrap.sc_align_center.sc_position_below.sc_subtitle_present .sc_item_title_line_left, .sc_title .sc_item_title_wrap.sc_align_center.sc_position_below.sc_subtitle_present .sc_item_title_line_right {
    margin-bottom: 3px
}

.sc_title .sc_item_title_wrap.sc_align_center.sc_position_below.sc_subtitle_present + .sc_item_subtitle_below {
    margin-top: -.3em
}

.slider_container.slider_pagination_bullets.slider_pagination_pos_bottom + .swiper-pagination-bullets {
    bottom: 2.8rem;
    position: absolute
}

.sc_blogger .post_featured + .sc_blogger_item_content .sc_blogger_item_title a {
    color: var(--theme-color-text_dark) !important
}

.sc_blogger .post_meta:empty {
    display: none
}

.sc_blogger .post_meta_categories a {
    color: var(--theme-color-text)
}

.sc_blogger .post_meta_categories a:hover {
    color: var(--theme-color-text_link)
}

.sc_blogger .sc_blogger_item_price {
    font-size: 19px;
    line-height: 1.2em;
    font-weight: 400;
    color: var(--theme-color-text_light)
}

.sc_blogger .sc_blogger_item_excerpt {
    font-size: 15px;
    line-height: 26px
}

.sc_blogger .sc_button .icon {
    display: block !important
}

.sc_blogger .sc_button .icon:after, .sc_blogger .sc_button .icon:before {
    -webkit-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
    display: inline-block;
    font-family: fontello;
    font-weight: 400;
    vertical-align: middle
}

.sc_blogger .sc_button .icon:before {
    content: '\e908';
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0)
}

.sc_blogger .sc_button:hover .icon:before {
    content: '\e908';
    -webkit-transform: translateX(21px);
    -ms-transform: translateX(21px);
    transform: translateX(21px);
    opacity: 0
}

.sc_blogger .sc_button .icon:after {
    -webkit-transform: translateX(-29px);
    -ms-transform: translateX(-29px);
    transform: translateX(-29px);
    content: '\E9C7';
    font-size: .75em;
    line-height: 1em;
    opacity: 0;
    width: 0
}

.sc_blogger .sc_button:hover .icon:after {
    -webkit-transform: translateX(-14px);
    -ms-transform: translateX(-14px);
    transform: translateX(-14px);
    opacity: 1;
    width: 21px
}

.sc_blogger .slider_container:not(.slider_multi):not(.slider_type_images), .sc_blogger .slider_container:not(.slider_multi):not(.slider_type_images) .slider-slide {
    min-height: 70px
}

.sc_blogger .slider_container .slider-slide {
    cursor: pointer
}

.sc_blogger .sc_blogger_title_tag_h6.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content {
    margin-top: 13px
}

.sc_blogger .sc_blogger_title_tag_h6.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content .sc_blogger_item_title:first-child {
    padding-top: 5px
}

.sc_blogger .sc_blogger_title_tag_h6 .post_meta_categories {
    margin-bottom: 8px
}

.sc_blogger .sc_blogger_title_tag_h6 .sc_blogger_item_title + .post_meta:not(.post_meta_categories) {
    margin-top: 7px
}

.sc_blogger .sc_blogger_title_tag_h5.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content {
    margin-top: 17px
}

.sc_blogger .sc_blogger_title_tag_h5.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content .sc_blogger_item_title:first-child {
    padding-top: 5px
}

.sc_blogger .sc_blogger_title_tag_h5 .sc_blogger_item_title + .sc_blogger_item_price {
    margin-top: 8px
}

.sc_blogger .sc_blogger_title_tag_h4 .post_info_bc {
    padding-top: 2.857rem;
    padding-bottom: 2.857rem
}

.sc_blogger .sc_blogger_title_tag_h4 .post_meta_categories {
    margin-bottom: 9px
}

.sc_blogger .sc_blogger_title_tag_h4 .sc_blogger_item_title + .post_meta:not(.post_meta_categories) {
    margin-top: 10px
}

.sc_blogger .sc_blogger_title_tag_h4 .sc_blogger_item_meta + .sc_blogger_item_excerpt {
    margin-top: 13px
}

.sc_blogger .sc_blogger_title_tag_h4 .sc_blogger_item_excerpt + .sc_blogger_item_button:not(.with_text) {
    margin-top: 17px
}

.sc_blogger .sc_blogger_title_tag_h3 [class*=post_info_] {
    padding-left: 3.571rem;
    padding-right: 3.571rem
}

.sc_blogger .sc_blogger_title_tag_h3 .post_info_bc {
    padding-top: 3.928rem;
    padding-bottom: 3.928rem
}

.sc_blogger .sc_blogger_title_tag_h3.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content {
    margin-top: 20px
}

.sc_blogger .sc_blogger_title_tag_h3 .post_meta_categories {
    margin-bottom: 8px
}

.sc_blogger .sc_blogger_title_tag_h3 .sc_blogger_item_title + .post_meta:not(.post_meta_categories) {
    margin-top: 10px
}

.sc_blogger .sc_blogger_title_tag_h3 .sc_blogger_item_excerpt + .post_meta:not(.post_meta_categories) {
    margin-top: 11px
}

.sc_blogger .sc_blogger_title_tag_h3 .sc_blogger_item_title + .sc_blogger_item_excerpt {
    margin-top: 9px
}

.sc_blogger .sc_blogger_item:not(.sc_blogger_item_default_classic_4).sc_blogger_item_align_center .sc_blogger_item_title, .sc_blogger_slider .sc_blogger_item:not(.sc_blogger_item_default_classic_4).sc_blogger_item_align_center .sc_blogger_item_title {
    margin-left: auto;
    margin-right: auto
}

.sc_blogger .sc_blogger_item:not(.sc_blogger_item_default_classic_4).sc_blogger_title_tag_h3.sc_blogger_item_align_center .sc_blogger_item_title {
    max-width: 13em;
    display: inline-block
}

.sc_layouts_column_align_center .sc_blogger .sc_blogger_item:not(.sc_blogger_item_default_classic_4).sc_blogger_item_align_none .sc_blogger_item_title, .sc_layouts_column_align_center .sc_blogger_slider .sc_blogger_item:not(.sc_blogger_item_default_classic_4).sc_blogger_item_align_none .sc_blogger_item_title {
    margin-left: auto;
    margin-right: auto
}

.sc_blogger_default .trx_addons_columns_wrap.columns_padding_bottom {
    margin-bottom: -36px
}

.sc_blogger_default .trx_addons_columns_wrap.columns_padding_bottom > [class*=trx_addons_column-] {
    padding-bottom: 36px
}

.sc_blogger_item_default.sc_blogger_item_image_position_top.sc_blogger_item_align_center .post_featured + .sc_blogger_item_content {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%
}

.sc_blogger_item_default_classic_2 .post_featured {
    overflow: visible
}

.sc_blogger_item_default_classic_2.sc_blogger_item_align_center [class*=post_info_] {
    text-align: center
}

.sc_blogger_item_default_classic_2 .sc_item_featured .post_info_bc {
    bottom: -11px;
    padding: 0 !important
}

.sc_blogger_item_default_classic_2 [class*=post_info_] .post_meta_categories {
    margin-bottom: 0 !important
}

.sc_blogger_item_default_classic_2 [class*=post_info_] .post_meta_categories a {
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    background-color: var(--theme-color-alter_bg_color);
    border-radius: 11px;
    color: var(--theme-color-alter_text);
    display: inline-block;
    padding: 3px 13px 3px 14px
}

.sc_blogger_item_default_classic_2 [class*=post_info_] .post_meta_categories a:hover {
    color: var(--theme-color-extra_dark);
    background-color: var(--theme-color-extra_hover)
}

.sc_blogger_item_default_classic_2.sc_blogger_title_tag_h3.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content {
    margin-top: 24px
}

.sc_blogger_default_over_bottom_center .slider_container.slider_pagination_bullets.slider_pagination_pos_bottom + .swiper-pagination-bullets {
    bottom: 2.1rem
}

.sc_blogger_item_default_over_bottom_center .post_featured .mask {
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, .95)));
    background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, .95));
    background: -o-linear-gradient(transparent, rgba(0, 0, 0, .95));
    background: linear-gradient(transparent, rgba(0, 0, 0, .95));
    opacity: 1
}

.sc_blogger_item_default_over_bottom_center .sc_item_featured [class*=post_info_] .post_meta_categories a {
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    background-color: var(--theme-color-extra_bg_color);
    border-radius: 10px;
    color: var(--theme-color-extra_dark);
    padding: 3px 11px 3px 12px
}

.sc_blogger_item_default_over_bottom_center .sc_item_featured [class*=post_info_] .post_meta_categories a:hover {
    color: var(--theme-color-extra_dark);
    background-color: var(--theme-color-extra_hover)
}

.sc_blogger_item_default_over_bottom_center:hover .sc_item_featured [class*=post_info_] .post_meta_categories a {
    color: var(--theme-color-extra_dark);
    background-color: var(--theme-color-extra_hover)
}

.sc_blogger_item_default_over_bottom_center.sc_blogger_item_align_center .sc_item_featured [class*=post_info_] {
    text-align: center
}

.sc_blogger_item_default_over_bottom_center.sc_blogger_title_tag_h4 .post_meta_categories {
    margin-top: 3px;
    margin-bottom: 18px
}

.sc_blogger_content .sc_blogger_item_wide {
    margin: 0;
    padding: 0;
    border-width: 0
}

.sc_blogger_content .sc_blogger_item_wide + .sc_blogger_item_wide {
    margin-top: 30px;
    padding-top: 30px;
    border-top-width: 1px;
    border-top-style: solid
}

.sc_blogger_item_wide.sc_blogger_item_with_image .sc_blogger_item_body {
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100% !important
}

.sc_blogger_item_wide.sc_blogger_item_with_image .sc_blogger_item_content, .sc_blogger_item_wide.sc_blogger_item_with_image.sc_blogger_item_image_position_left .sc_blogger_item_content {
    padding: 0
}

.sc_blogger_item_wide.sc_blogger_item_with_image.small_image.sc_blogger_item_image_position_left .sc_blogger_item_content {
    padding-left: 30px
}

.sc_blogger_content .sc_blogger_item_list.sc_blogger_item_with_image {
    border-bottom: none;
    padding-bottom: 0
}

.sc_blogger_content .sc_blogger_item_list.sc_blogger_item_with_image:last-child {
    margin-bottom: 0
}

.sc_blogger_list .post_meta:empty {
    display: none
}

.sc_blogger_item_list.sc_blogger_item_with_image {
    margin-top: 0 !important;
    position: relative
}

.sc_blogger_item_list.sc_blogger_item_with_image.sc_blogger_item_image_position_left .sc_blogger_item_body {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.sc_blogger_item_list.sc_blogger_item_with_image:not(.sc_blogger_item_image_position_top) .sc_blogger_item_featured {
    min-width: 105px
}

.sc_blogger_item_list.sc_blogger_item_with_image:not(.sc_blogger_item_image_position_top) .sc_blogger_item_content {
    max-width: calc(100% - 80px)
}

.sc_blogger_item_list.sc_blogger_item_with_image.sc_blogger_item_image_position_left:not(.small_image):not(.tiny_image) .sc_blogger_item_content {
    padding-left: 25px
}


.sc_blogger_item_list.sc_blogger_item_with_image.sc_blogger_item_with_numbers:not(.sc_blogger_item_image_position_top):hover .sc_blogger_item_featured:after {
    background-color: var(--theme-color-text_link);
    color: var(--theme-color-inverse_text)
}


.sc_blogger_item_list.sc_blogger_item_list_with_rounded_image .featured_bg_wrapper, .sc_blogger_item_list.sc_blogger_item_list_with_rounded_image .post_featured {
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%
}


.sc_blogger_content .sc_blogger_item_list.sc_blogger_item_with_image {
    margin-bottom: 25px
}

.sc_blogger_content .sc_blogger_item_list.sc_blogger_item_with_image:last-child {
    margin-bottom: 0
}

.sc_blogger_content .sc_blogger_item {
    border-color: var(--theme-color-bd_color)
}

.sc_item_filters {
    margin-bottom: 2.45rem
}

.sc_item_filters_tabs {
    font-size: 1.142rem !important;
    line-height: 1.5em
}

.sc_item_filters_tabs li {
    margin: 0;
    padding: 0 0 0 1.2em
}

.sc_item_filters_tabs li a {
    color: var(--theme-color-text_light)
}

.sc_item_filters_tabs li a:hover {
    color: var(--theme-color-text_dark);
    border: none
}

.sc_item_filters_align_right {
    border: none;
    padding: 0 !important
}

.sc_item_filters_align_right .sc_item_filters_tabs li {
    padding: 0 1.2em 0 0
}

:root {
    --theme-var-elm_gap_nogap: 0px;
    --theme-var-elm_gap_narrow: 10px;
    --theme-var-elm_gap_default: 20px;
    --theme-var-elm_gap_extended: 30px;
    --theme-var-elm_gap_wide: 40px;
    --theme-var-elm_gap_wider: 60px;
    --theme-var-elm_add_page_margins: var(--theme-var-elm_gap_extended)
}

.skocak-section[class*=scheme_] {
    background-color: var(--theme-color-bg_color)
}

.post_content > .skocak {
    margin-left: calc(-1 * var(--theme-var-elm_add_page_margins) / 2);
    margin-right: calc(-1 * var(--theme-var-elm_add_page_margins) / 2)
}

.skocak-column-gap-default > .skocak-column > .skocak-element-populated {
    padding: calc(var(--theme-var-elm_gap_default) / 2)
}

.skocak-column-gap-extended > .skocak-column > .skocak-element-populated {
    padding: calc(var(--theme-var-elm_gap_extended) / 2)
}

.skocak-column-gap-wide > .skocak-column > .skocak-element-populated {
    padding: calc(var(--theme-var-elm_gap_wide) / 2)
}

.skocak-container > .skocak-column > .skocak-element-populated {
    padding-top: 0;
    padding-bottom: 0
}

.skocak-section.skocak-section-boxed:not(.skocak-section-with-custom-width) > .skocak-column-gap-no {
    max-width: var(--theme-var-page)
}

.skocak-section.skocak-section-boxed:not(.skocak-section-with-custom-width) > .skocak-column-gap-default {
    max-width: calc(var(--theme-var-page) + var(--theme-var-elm_gap_default))
}

.skocak-section.skocak-section-boxed:not(.skocak-section-with-custom-width) > .skocak-column-gap-extended {
    max-width: calc(var(--theme-var-page) + var(--theme-var-elm_gap_extended))
}

.skocak-section.skocak-section-boxed:not(.skocak-section-with-custom-width) > .skocak-column-gap-wide {
    max-width: calc(var(--theme-var-page) + var(--theme-var-elm_gap_wide))
}

.skocak-widget-container {
    border-color: var(--theme-color-bd_color)
}

.skocak-element.skocak-widget:not(:last-child), .skocak-widget:not(:last-child) {
    margin-bottom: 0
}

html {
    font-size: 14px
}

.pk-icon-facebook:before {
    content: "\E9A8";
    font-family: fontello
}

.pk-icon-twitter:before {
    font-size: 89%
}

.pk-icon-youtube:before {
    content: "\E9AB";
    font-family: fontello
}

.pk-icon-instagram:before {
    font-size: .8em;
    line-height: 1.2em
}

.pk-social-links-wrap .pk-social-links-items {
    margin: 0 -9px 0 0
}

.pk-social-links-wrap .pk-social-links-item {
    padding: 0 9px 0 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.pk-social-links-wrap .pk-social-links-item a .pk-icon {
    -webkit-animation: animation_back_down .3s ease-out forwards;
    animation: animation_back_down .3s ease-out forwards;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
}

.pk-social-links-wrap .pk-social-links-item a:hover .pk-icon {
    -webkit-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation: animation_jump_up .3s ease-out forwards;
    animation: animation_jump_up .3s ease-out forwards
}

.pk-social-links-wrap.pk-social-links-template-columns .pk-social-links-link {
    padding: 0 !important
}

.pk-social-links-wrap .pk-font-secondary {
    font-size: 1.214rem;
    line-height: 1.5em;
    font-weight: 400;
    color: var(--theme-color-text_dark)
}

.pk-social-links-wrap .pk-font-secondary {
    margin-top: .55rem
}

.pk-social-links-wrap .pk-social-links-label {
    font-size: 13px;
    line-height: 1.33em;
    font-weight: 400;
    color: var(--theme-color-text);
    letter-spacing: 0;
    margin-top: 0
}

.pk-social-links-wrap .pk-social-links-count {
    font-weight: 600;
}

.pk-social-links-wrap.pk-social-links-scheme-default .pk-social-links-icon {
    width: 1.15em;
    height: 1.15em;
    line-height: 1.15em;
    text-align: center;
    font-size: 1.785rem
}

.pk-social-links-wrap.pk-social-links-scheme-rounded .pk-social-links-icon {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    width: 4.572rem;
    height: 4.572rem;
    line-height: 4.4rem;
    background-color: var(--theme-color-bg_color);
    border: 1px solid var(--theme-color-bd_color);
    font-size: 1.142rem
}

.pk-social-links-wrap .pk-social-links-twitter .pk-social-links-link {
    color: #00aced
}

.pk-social-links-wrap .pk-social-links-twitter .pk-social-links-link:hover .pk-social-links-icon {
    color: #21c2ff
}

.pk-social-links-wrap .pk-social-links-facebook .pk-social-links-link {
    color: #3b5998
}

.pk-social-links-wrap .pk-social-links-facebook .pk-social-links-link:hover .pk-social-links-icon {
    color: #4c70ba
}

.pk-social-links-wrap .pk-social-links-instagram .pk-social-links-link {
    color: #c862dc
}

.pk-social-links-wrap .pk-social-links-instagram .pk-social-links-link:hover .pk-social-links-icon {
    color: #cc6edf
}

.pk-social-links-wrap .pk-social-links-youtube .pk-social-links-link {
    color: red
}

.pk-social-links-wrap .pk-social-links-youtube .pk-social-links-link:hover .pk-social-links-icon {
    color: #f33
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-items {
    display: block;
    margin: -.3rem
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-item {
    display: inline-block;
    padding: .3rem
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-link {
    background-color: transparent !important;
    color: var(--theme-color-text_dark) !important;
    padding: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-link:hover {
    background-color: transparent;
    color: var(--theme-color-text_link) !important
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-icon {
    font-size: 1.3rem;
    line-height: 19px;
    height: 20px;
    text-align: center
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-item:first-child .pk-social-links-icon {
    text-align: left
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-link .pk-social-links-count {
    font-size: 1rem;
    font-weight: 400;
    color: var(--theme-color-text);
    display: block;
    opacity: 1;
    padding: 0 .4rem 0 .2rem;
    margin-top: .2em
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-link:hover .pk-social-links-count {
    color: var(--theme-color-text)
}

.pk-social-links-wrap.pk-social-links-template-default .pk-social-links-link .pk-social-links-count, .pk-social-links-wrap.pk-social-links-template-default .pk-social-links-link:hover .pk-social-links-count {
    color: var(--theme-color-text_dark)
}

.sc_blogger_item_default_over_bottom_center .post_featured .mask {
    opacity: .3
}

:root {
    --theme-var-page_width: 1290px;
    --theme-var-page_boxed_extra: 60px;
    --theme-var-page_fullwide_max: 1920px;
    --theme-var-page_fullwide_extra: 130px;
    --theme-var-sidebar_width: 390px;
    --theme-var-grid_gap: 30px;
    --theme-var-rad: 30px;
    --theme-var-sidebar_proportional: 1;
    --theme-var-rad_koef: 1;
    --theme-var-sidebar_prc: 0.30232558139535;
    --theme-var-sidebar_gap_prc: 0.038759689922481;
    --theme-var-page_narrow_width: 1290px;
    --theme-var-sidebar_gap_width: 50px
}

:root {
    --theme-font-p_font-family: Lora, serif;
    --theme-font-p_font-size: 1.2142857rem;
    --theme-font-p_font-weight: 400;
    --theme-font-p_font-style: normal;
    --theme-font-p_line-height: 1.9em;
    --theme-font-p_text-decoration: inherit;
    --theme-font-p_text-transform: none;
    --theme-font-p_letter-spacing: -0.01em;
    --theme-font-p_margin-top: 0em;
    --theme-font-p_margin-bottom: 1.95em;
    --theme-font-post_font-family: inherit;
    --theme-font-post_font-size: inherit;
    --theme-font-post_font-weight: inherit;
    --theme-font-post_font-style: inherit;
    --theme-font-post_line-height: inherit;
    --theme-font-post_text-decoration: inherit;
    --theme-font-post_text-transform: inherit;
    --theme-font-post_letter-spacing: inherit;
    --theme-font-post_margin-top: inherit;
    --theme-font-post_margin-bottom: inherit;
    --theme-font-h1_font-family: "Playfair Display", sans-serif;
    --theme-font-h1_font-size: 50px;
    --theme-font-h1_font-weight: 400;
    --theme-font-h1_font-style: normal;
    --theme-font-h1_line-height: 51px;
    --theme-font-h1_text-decoration: none;
    --theme-font-h1_text-transform: none;
    --theme-font-h1_letter-spacing: 0px;
    --theme-font-h1_margin-top: 1.1em;
    --theme-font-h1_margin-bottom: 0.21em;
    --theme-font-h2_font-family: "Playfair Display", sans-serif;
    --theme-font-h2_font-size: 35px;
    --theme-font-h2_font-weight: 400;
    --theme-font-h2_font-style: normal;
    --theme-font-h2_line-height: 38px;
    --theme-font-h2_text-decoration: none;
    --theme-font-h2_text-transform: none;
    --theme-font-h2_letter-spacing: 0px;
    --theme-font-h2_margin-top: 1.25em;
    --theme-font-h2_margin-bottom: 0.52em;
    --theme-font-h3_font-family: "Playfair Display", sans-serif;
    --theme-font-h3_font-size: 32px;
    --theme-font-h3_font-weight: 400;
    --theme-font-h3_font-style: normal;
    --theme-font-h3_line-height: 32px;
    --theme-font-h3_text-decoration: none;
    --theme-font-h3_text-transform: none;
    --theme-font-h3_letter-spacing: 0px;
    --theme-font-h3_margin-top: 1.35em;
    --theme-font-h3_margin-bottom: 0.5em;
    --theme-font-h4_font-family: "Playfair Display", sans-serif;
    --theme-font-h4_font-size: 26px;
    --theme-font-h4_font-weight: 400;
    --theme-font-h4_font-style: normal;
    --theme-font-h4_line-height: 28px;
    --theme-font-h4_text-decoration: none;
    --theme-font-h4_text-transform: none;
    --theme-font-h4_letter-spacing: 0px;
    --theme-font-h4_margin-top: 1.45em;
    --theme-font-h4_margin-bottom: 0.55em;
    --theme-font-h5_font-family: "Playfair Display", sans-serif;
    --theme-font-h5_font-size: 21px;
    --theme-font-h5_font-weight: 400;
    --theme-font-h5_font-style: normal;
    --theme-font-h5_line-height: 24px;
    --theme-font-h5_text-decoration: none;
    --theme-font-h5_text-transform: none;
    --theme-font-h5_letter-spacing: 0px;
    --theme-font-h5_margin-top: 1.5em;
    --theme-font-h5_margin-bottom: 0.7em;
    --theme-font-h6_font-family: "Playfair Display", sans-serif;
    --theme-font-h6_font-size: 18px;
    --theme-font-h6_font-weight: 400;
    --theme-font-h6_font-style: normal;
    --theme-font-h6_line-height: 22px;
    --theme-font-h6_text-decoration: none;
    --theme-font-h6_text-transform: none;
    --theme-font-h6_letter-spacing: 0px;
    --theme-font-h6_margin-top: 1.45em;
    --theme-font-h6_margin-bottom: 0.75em;
    --theme-font-logo_font-family: "Playfair Display", sans-serif;
    --theme-font-logo_font-size: 1.8em;
    --theme-font-logo_font-weight: 400;
    --theme-font-logo_font-style: normal;
    --theme-font-logo_line-height: 1.25em;
    --theme-font-logo_text-decoration: none;
    --theme-font-logo_text-transform: uppercase;
    --theme-font-logo_letter-spacing: 1px;
    --theme-font-button_font-family: Karla, sans-serif;
    --theme-font-button_font-size: 13px;
    --theme-font-button_font-weight: 700;
    --theme-font-button_font-style: normal;
    --theme-font-button_line-height: 19px;
    --theme-font-button_text-decoration: none;
    --theme-font-button_text-transform: uppercase;
    --theme-font-button_letter-spacing: 0.12em;
    --theme-font-input_font-family: Karla, sans-serif;
    --theme-font-input_font-size: 14px;
    --theme-font-input_font-weight: 400;
    --theme-font-input_font-style: normal;
    --theme-font-input_line-height: 21px;
    --theme-font-input_text-decoration: none;
    --theme-font-input_text-transform: none;
    --theme-font-input_letter-spacing: 0px;
    --theme-font-info_font-family: Karla, sans-serif;
    --theme-font-info_font-size: 12px;
    --theme-font-info_font-weight: 400;
    --theme-font-info_font-style: normal;
    --theme-font-info_line-height: 18px;
    --theme-font-info_text-decoration: none;
    --theme-font-info_text-transform: uppercase;
    --theme-font-info_letter-spacing: 0px;
    --theme-font-info_margin-top: 0.75em;
    --theme-font-info_margin-bottom: inherit;
    --theme-font-menu_font-family: Karla, sans-serif;
    --theme-font-menu_font-size: 1.0715rem;
    --theme-font-menu_font-weight: 700;
    --theme-font-menu_font-style: normal;
    --theme-font-menu_line-height: 1.5em;
    --theme-font-menu_text-decoration: none;
    --theme-font-menu_text-transform: uppercase;
    --theme-font-menu_letter-spacing: 0.14em;
    --theme-font-submenu_font-family: Karla, sans-serif;
    --theme-font-submenu_font-size: 1.0715rem;
    --theme-font-submenu_font-weight: 400;
    --theme-font-submenu_font-style: normal;
    --theme-font-submenu_line-height: 1.5em;
    --theme-font-submenu_text-decoration: none;
    --theme-font-submenu_text-transform: none;
    --theme-font-submenu_letter-spacing: 0px
}

body {
    font-family: var(--theme-font-p_font-family);
    font-size: var(--theme-font-p_font-size);
    font-weight: var(--theme-font-p_font-weight);
    font-style: var(--theme-font-p_font-style);
    line-height: var(--theme-font-p_line-height);
    text-transform: var(--theme-font-p_text-transform);
    letter-spacing: var(--theme-font-p_letter-spacing)
}

ul {
    margin-top: var(--theme-font-p_margin-top);
    margin-bottom: var(--theme-font-p_margin-bottom)
}

h2 {
    font-family: var(--theme-font-h2_font-family);
    font-size: var(--theme-font-h2_font-size);
    font-weight: var(--theme-font-h2_font-weight);
    font-style: var(--theme-font-h2_font-style);
    line-height: var(--theme-font-h2_line-height);
    text-decoration: var(--theme-font-h2_text-decoration);
    text-transform: var(--theme-font-h2_text-transform);
    letter-spacing: var(--theme-font-h2_letter-spacing);
    margin-top: var(--theme-font-h2_margin-top);
    margin-bottom: var(--theme-font-h2_margin-bottom)
}

h3 {
    font-family: var(--theme-font-h3_font-family);
    font-size: var(--theme-font-h3_font-size);
    font-weight: var(--theme-font-h3_font-weight);
    font-style: var(--theme-font-h3_font-style);
    line-height: var(--theme-font-h3_line-height);
    text-decoration: var(--theme-font-h3_text-decoration);
    text-transform: var(--theme-font-h3_text-transform);
    letter-spacing: var(--theme-font-h3_letter-spacing);
    margin-top: var(--theme-font-h3_margin-top);
    margin-bottom: var(--theme-font-h3_margin-bottom)
}

h4 {
    font-family: var(--theme-font-h4_font-family);
    font-size: var(--theme-font-h4_font-size);
    font-weight: var(--theme-font-h4_font-weight);
    font-style: var(--theme-font-h4_font-style);
    line-height: var(--theme-font-h4_line-height);
    text-decoration: var(--theme-font-h4_text-decoration);
    text-transform: var(--theme-font-h4_text-transform);
    letter-spacing: var(--theme-font-h4_letter-spacing);
    margin-top: var(--theme-font-h4_margin-top);
    margin-bottom: var(--theme-font-h4_margin-bottom)
}

h5 {
    font-family: var(--theme-font-h5_font-family);
    font-size: var(--theme-font-h5_font-size);
    font-weight: var(--theme-font-h5_font-weight);
    font-style: var(--theme-font-h5_font-style);
    line-height: var(--theme-font-h5_line-height);
    text-decoration: var(--theme-font-h5_text-decoration);
    text-transform: var(--theme-font-h5_text-transform);
    letter-spacing: var(--theme-font-h5_letter-spacing);
    margin-top: var(--theme-font-h5_margin-top);
    margin-bottom: var(--theme-font-h5_margin-bottom)
}

h6 {
    font-family: var(--theme-font-h6_font-family);
    font-size: var(--theme-font-h6_font-size);
    font-weight: var(--theme-font-h6_font-weight);
    font-style: var(--theme-font-h6_font-style);
    line-height: var(--theme-font-h6_line-height);
    text-decoration: var(--theme-font-h6_text-decoration);
    text-transform: var(--theme-font-h6_text-transform);
    letter-spacing: var(--theme-font-h6_letter-spacing);
    margin-top: var(--theme-font-h6_margin-top);
    margin-bottom: var(--theme-font-h6_margin-bottom)
}

.pk-font-secondary.pk-social-links-label, .pk-social-links-label {
    font-family: var(--theme-font-h5_font-family)
}

.pk-font-secondary, .post_date, .post_item_single .post_content .post_meta, .post_meta_item {
    font-family: var(--theme-font-info_font-family)
}

.post_meta {
    font-family: var(--theme-font-info_font-family);
    font-size: var(--theme-font-info_font-size);
    font-weight: var(--theme-font-info_font-weight);
    font-style: var(--theme-font-info_font-style);
    line-height: var(--theme-font-info_line-height);
    text-decoration: var(--theme-font-info_text-decoration);
    text-transform: var(--theme-font-info_text-transform);
    letter-spacing: var(--theme-font-info_letter-spacing);
    margin-top: var(--theme-font-info_margin-top)
}

.sc_layouts_menu_nav {
    font-family: var(--theme-font-menu_font-family);
    font-size: var(--theme-font-menu_font-size);
    line-height: var(--theme-font-menu_line-height)
}

.menu_main_nav > li > a, .sc_layouts_menu_nav > li > a {
    font-weight: var(--theme-font-menu_font-weight);
    font-style: var(--theme-font-menu_font-style);
    text-decoration: var(--theme-font-menu_text-decoration);
    text-transform: var(--theme-font-menu_text-transform);
    letter-spacing: var(--theme-font-menu_letter-spacing)
}

.menu_main_nav > li > ul, .sc_layouts_menu_nav > li > ul {
    font-family: var(--theme-font-submenu_font-family);
    font-size: var(--theme-font-submenu_font-size);
    line-height: var(--theme-font-submenu_line-height)
}

.menu_main_nav > li ul > li > a, .sc_layouts_menu_nav > li ul > li > a {
    font-weight: var(--theme-font-submenu_font-weight);
    font-style: var(--theme-font-submenu_font-style);
    text-decoration: var(--theme-font-submenu_text-decoration);
    text-transform: var(--theme-font-submenu_text-transform);
    letter-spacing: var(--theme-font-submenu_letter-spacing)
}

.menu_mobile .menu_mobile_nav_area > ul {
    font-family: var(--theme-font-menu_font-family)
}

.menu_mobile .menu_mobile_nav_area > ul > li ul {
    font-family: var(--theme-font-submenu_font-family)
}

.sc_item_subtitle {
    font-family: var(--theme-font-p_font-family)
}

.sc_blogger_item_list.sc_blogger_item_with_image.sc_blogger_item_with_numbers:not(.sc_blogger_item_image_position_top) .sc_blogger_item_featured:after, .sc_blogger_item_price, .sc_item_filters_tabs {
    font-family: var(--theme-font-info_font-family)
}

.sc_button, .sc_button.sc_button_simple {
    font-family: var(--theme-font-button_font-family);
    font-size: var(--theme-font-button_font-size);
    font-weight: var(--theme-font-button_font-weight);
    font-style: var(--theme-font-button_font-style);
    line-height: var(--theme-font-button_line-height);
    text-decoration: var(--theme-font-button_text-decoration);
    text-transform: var(--theme-font-button_text-transform);
    letter-spacing: var(--theme-font-button_letter-spacing)
}

.scheme_default, body.scheme_default {
    --theme-color-bg_color: #ffffff;
    --theme-color-bd_color: #D8E3E3;
    --theme-color-bd_hover: #C2D0D0;
    --theme-color-text: #707376;
    --theme-color-text_dark: #161921;
    --theme-color-text_light: #9396A2;
    --theme-color-text_link: #E6B088;
    --theme-color-text_hover: #db9b6b;
    --theme-color-text_link2: #6AC2C2;
    --theme-color-text_hover2: #51AAAA;
    --theme-color-text_link3: #2948D0;
    --theme-color-text_hover3: #1530A8;
    --theme-color-alter_bg_color: #EDF4F4;
    --theme-color-alter_bg_hover: #E7F0F0;
    --theme-color-alter_bd_color: #D8E3E3;
    --theme-color-alter_bd_hover: #C2D0D0;
    --theme-color-alter_text: #707376;
    --theme-color-alter_dark: #161921;
    --theme-color-alter_light: #9396A2;
    --theme-color-alter_link: #E6B088;
    --theme-color-alter_hover: #db9b6b;
    --theme-color-alter_link2: #6AC2C2;
    --theme-color-alter_hover2: #51AAAA;
    --theme-color-alter_link3: #2948D0;
    --theme-color-alter_hover3: #1530A8;
    --theme-color-extra_bg_color: #001E42;
    --theme-color-extra_bg_hover: #021327;
    --theme-color-extra_bd_color: #1E3858;
    --theme-color-extra_bd_hover: #314966;
    --theme-color-extra_text: #999DAC;
    --theme-color-extra_dark: #FCFCFC;
    --theme-color-extra_light: #9396A2;
    --theme-color-extra_link: #FCFCFC;
    --theme-color-extra_hover: #E6B088;
    --theme-color-input_bg_color: #FFFFFF;
    --theme-color-input_bg_hover: #EDF4F4;
    --theme-color-input_bd_color: #D8E3E3;
    --theme-color-input_bd_hover: #C2D0D0;
    --theme-color-input_text: #707376;
    --theme-color-input_dark: #161921;
    --theme-color-input_light: #9396A2;
    --theme-color-inverse_bd_color: #FFFFFF;
    --theme-color-inverse_bd_hover: #FFFFFF;
    --theme-color-inverse_text: #FFFFFF;
    --theme-color-inverse_dark: #FCFCFC;
    --theme-color-inverse_light: #FFFFFF;
    --theme-color-inverse_link: #FFFFFF;
    --theme-color-inverse_hover: #FFFFFF;
    --theme-color-bg_color_0: rgba(255, 255, 255, 0);
    --theme-color-bg_color_02: rgba(255, 255, 255, 0.2);
    --theme-color-bg_color_07: rgba(255, 255, 255, 0.7);
    --theme-color-bg_color_08: rgba(255, 255, 255, 0.8);
    --theme-color-bg_color_09: rgba(255, 255, 255, 0.9);
    --theme-color-bd_color_05: rgba(216, 227, 227, 0.5);
    --theme-color-alter_bg_color_07: rgba(237, 244, 244, 0.7);
    --theme-color-alter_bg_color_04: rgba(237, 244, 244, 0.4);
    --theme-color-alter_bg_color_00: rgba(237, 244, 244, 0);
    --theme-color-alter_bg_color_02: rgba(237, 244, 244, 0.2);
    --theme-color-alter_bd_color_02: rgba(216, 227, 227, 0.2);
    --theme-color-alter_dark_075: rgba(22, 25, 33, 0.75);
    --theme-color-alter_link_02: rgba(230, 176, 136, 0.2);
    --theme-color-alter_link_07: rgba(230, 176, 136, 0.7);
    --theme-color-extra_bg_color_002: rgba(0, 30, 66, 0.02);
    --theme-color-extra_bg_color_015: rgba(0, 30, 66, 0.15);
    --theme-color-extra_bg_color_05: rgba(0, 30, 66, 0.5);
    --theme-color-extra_bg_color_07: rgba(0, 30, 66, 0.7);
    --theme-color-extra_bg_hover_01: rgba(2, 19, 39, 0.1);
    --theme-color-extra_dark_07: rgba(252, 252, 252, 0.7);
    --theme-color-extra_dark_092: rgba(252, 252, 252, 0.92);
    --theme-color-extra_link_02: rgba(252, 252, 252, 0.2);
    --theme-color-extra_link_07: rgba(252, 252, 252, 0.7);
    --theme-color-text_dark_005: rgba(22, 25, 33, 0.05);
    --theme-color-text_dark_01: rgba(22, 25, 33, 0.1);
    --theme-color-text_dark_07: rgba(22, 25, 33, 0.7);
    --theme-color-text_dark_095: rgba(22, 25, 33, 0.95);
    --theme-color-text_link_01: rgba(230, 176, 136, 0.1);
    --theme-color-text_link_02: rgba(230, 176, 136, 0.2);
    --theme-color-text_link_07: rgba(230, 176, 136, 0.7);
    --theme-color-text_hover_01: rgba(219, 155, 107, 0.1);
    --theme-color-text_link3_03: rgba(41, 72, 208, 0.3);
    --theme-color-text_hover3_03: rgba(21, 48, 168, 0.3);
    --theme-color-input_dark_06: rgba(22, 25, 33, 0.6);
    --theme-color-text_link_blend: #f2c39b;
    --theme-color-alter_link_blend: #f2c39b
}

.scheme_dark {
    --theme-color-bg_color: #001E42;
    --theme-color-bd_color: #1E3858;
    --theme-color-bd_hover: #314966;
    --theme-color-text: #999DAC;
    --theme-color-text_dark: #FCFCFC;
    --theme-color-text_light: #7A7F91;
    --theme-color-text_link: #E6B088;
    --theme-color-text_hover: #db9b6b;
    --theme-color-text_link2: #6AC2C2;
    --theme-color-text_hover2: #51AAAA;
    --theme-color-text_link3: #2948D0;
    --theme-color-text_hover3: #1530A8;
    --theme-color-alter_bg_color: #021327;
    --theme-color-alter_bg_hover: #353740;
    --theme-color-alter_bd_color: #1E3858;
    --theme-color-alter_bd_hover: #314966;
    --theme-color-alter_text: #999DAC;
    --theme-color-alter_dark: #FCFCFC;
    --theme-color-alter_light: #7A7F91;
    --theme-color-alter_link: #E6B088;
    --theme-color-alter_hover: #db9b6b;
    --theme-color-alter_link2: #6AC2C2;
    --theme-color-alter_hover2: #51AAAA;
    --theme-color-alter_link3: #2948D0;
    --theme-color-alter_hover3: #1530A8;
    --theme-color-extra_bg_color: #001E42;
    --theme-color-extra_bg_hover: #021327;
    --theme-color-extra_bd_color: #1E3858;
    --theme-color-extra_bd_hover: #314966;
    --theme-color-extra_text: #999DAC;
    --theme-color-extra_dark: #FCFCFC;
    --theme-color-extra_light: #7A7F91;
    --theme-color-extra_link: #FCFCFC;
    --theme-color-extra_hover: #E6B088;
    --theme-color-input_bg_color: #001E42;
    --theme-color-input_bg_hover: #031933;
    --theme-color-input_bd_color: #1E3858;
    --theme-color-input_bd_hover: #314966;
    --theme-color-input_text: #999DAC;
    --theme-color-input_dark: #FCFCFC;
    --theme-color-input_light: #7A7F91;
    --theme-color-inverse_bd_color: #FFFFFF;
    --theme-color-inverse_bd_hover: #FFFFFF;
    --theme-color-inverse_text: #FFFFFF;
    --theme-color-inverse_dark: #FCFCFC;
    --theme-color-inverse_light: #FFFFFF;
    --theme-color-inverse_link: #FFFFFF;
    --theme-color-inverse_hover: #FFFFFF;
    --theme-color-bg_color_0: rgba(0, 30, 66, 0);
    --theme-color-bg_color_02: rgba(0, 30, 66, 0.2);
    --theme-color-bg_color_07: rgba(0, 30, 66, 0.7);
    --theme-color-bg_color_08: rgba(0, 30, 66, 0.8);
    --theme-color-bg_color_09: rgba(0, 30, 66, 0.9);
    --theme-color-bd_color_05: rgba(30, 56, 88, 0.5);
    --theme-color-alter_bg_color_07: rgba(2, 19, 39, 0.7);
    --theme-color-alter_bg_color_04: rgba(2, 19, 39, 0.4);
    --theme-color-alter_bg_color_00: rgba(2, 19, 39, 0);
    --theme-color-alter_bg_color_02: rgba(2, 19, 39, 0.2);
    --theme-color-alter_bd_color_02: rgba(30, 56, 88, 0.2);
    --theme-color-alter_dark_075: rgba(252, 252, 252, 0.75);
    --theme-color-alter_link_02: rgba(230, 176, 136, 0.2);
    --theme-color-alter_link_07: rgba(230, 176, 136, 0.7);
    --theme-color-extra_bg_color_002: rgba(0, 30, 66, 0.02);
    --theme-color-extra_bg_color_015: rgba(0, 30, 66, 0.15);
    --theme-color-extra_bg_color_05: rgba(0, 30, 66, 0.5);
    --theme-color-extra_bg_color_07: rgba(0, 30, 66, 0.7);
    --theme-color-extra_bg_hover_01: rgba(2, 19, 39, 0.1);
    --theme-color-extra_dark_07: rgba(252, 252, 252, 0.7);
    --theme-color-extra_dark_092: rgba(252, 252, 252, 0.92);
    --theme-color-extra_link_02: rgba(252, 252, 252, 0.2);
    --theme-color-extra_link_07: rgba(252, 252, 252, 0.7);
    --theme-color-text_dark_005: rgba(252, 252, 252, 0.05);
    --theme-color-text_dark_01: rgba(252, 252, 252, 0.1);
    --theme-color-text_dark_07: rgba(252, 252, 252, 0.7);
    --theme-color-text_dark_095: rgba(252, 252, 252, 0.95);
    --theme-color-text_link_01: rgba(230, 176, 136, 0.1);
    --theme-color-text_link_02: rgba(230, 176, 136, 0.2);
    --theme-color-text_link_07: rgba(230, 176, 136, 0.7);
    --theme-color-text_hover_01: rgba(219, 155, 107, 0.1);
    --theme-color-text_link3_03: rgba(41, 72, 208, 0.3);
    --theme-color-text_hover3_03: rgba(21, 48, 168, 0.3);
    --theme-color-input_dark_06: rgba(252, 252, 252, 0.6);
    --theme-color-text_link_blend: #f2c39b;
    --theme-color-alter_link_blend: #f2c39b
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    word-wrap: normal !important;
    border: 0;
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    overflow-wrap: normal !important;
    padding: 0;
    position: absolute !important;
    width: 1px
}

.screen-reader-text:focus {
    clip: auto !important;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6);
    clip-path: none;
    color: #2b2d2f;
    display: block;
    font-size: .875rem;
    font-weight: 700;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000
}

.skocak-3959 .skocak-element.skocak-element-e0458a8 > .skocak-container > .skocak-column > .skocak-widget-wrap {
    align-content: center;
    align-items: center
}

.skocak-3959 .skocak-element.skocak-element-e0458a8:not(.skocak-motion-effects-element-type-background) {
    background-size: cover
}

.skocak-3959 .skocak-element.skocak-element-e0458a8 > .skocak-background-overlay {
    background-color: #000;
    opacity: .1;
    transition: background .3s, border-radius .3s, opacity .3s
}

.skocak-3959 .skocak-element.skocak-element-e0458a8 {
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s
}

.skocak-3959 .skocak-element.skocak-element-878f403 {
    --spacer-size: 40px
}

.skocak-3959 .skocak-element.skocak-element-4e8b504 {
    --spacer-size: 60px
}

.skocak-3959 .skocak-element.skocak-element-5f28ab2 .logo_image {
    max-height: 108px
}

.skocak-3959 .skocak-element.skocak-element-7335a83 {
    --spacer-size: 60px
}

.skocak-3959 .skocak-element.skocak-element-51c72e7 {
    --spacer-size: 40px
}

.skocak-3959 .skocak-element.skocak-element-551a3cd > .skocak-container > .skocak-column > .skocak-widget-wrap {
    align-content: center;
    align-items: center
}

.skocak-3959 .skocak-element.skocak-element-99483d3 > .skocak-container > .skocak-column > .skocak-widget-wrap {
    align-content: center;
    align-items: center
}

@media (max-width: 1023px) {
    .skocak-3959 .skocak-element.skocak-element-cd9555c > .skocak-widget-container {
        padding: 0
    }

    .skocak-3959 .skocak-element.skocak-element-4e8b504 {
        --spacer-size: 30px
    }

    .skocak-3959 .skocak-element.skocak-element-5f28ab2 .logo_image {
        max-height: 55px
    }

    .skocak-3959 .skocak-element.skocak-element-7335a83 {
        --spacer-size: 30px
    }

    .skocak-3959 .skocak-element.skocak-element-f4a4b0d > .skocak-element-populated.skocak-widget-wrap {
        padding: 0
    }

    .skocak-3959 .skocak-element.skocak-element-20f0616 > .skocak-widget-container {
        margin: 2px 0 0 0
    }
}

@media (max-width: 767px) {
    .skocak-3959 .skocak-element.skocak-element-a0767c1 {
        width: 50%
    }

    .skocak-3959 .skocak-element.skocak-element-9fe58ee {
        width: 50%
    }

    .skocak-3959 .skocak-element.skocak-element-f4a4b0d {
        width: 50%
    }

    .skocak-3959 .skocak-element.skocak-element-f4a4b0d > .skocak-element-populated.skocak-widget-wrap {
        padding: 0 10px 0 10px
    }

    .skocak-3959 .skocak-element.skocak-element-35f2041 {
        width: 50%
    }

    .skocak-3959 .skocak-element.skocak-element-256013b8 {
        width: 40%
    }

    .skocak-3959 .skocak-element.skocak-element-4e37e108 {
        width: 20%
    }
}

@media (min-width: 768px) {
    .skocak-3959 .skocak-element.skocak-element-f4a4b0d {
        width: 66%
    }

    .skocak-3959 .skocak-element.skocak-element-35f2041 {
        width: 17%
    }

    .skocak-3959 .skocak-element.skocak-element-256013b8 {
        width: 15%
    }

    .skocak-3959 .skocak-element.skocak-element-4e37e108 {
        width: 69.333%
    }
}

@media (max-width: 1023px) and (min-width: 768px) {
    .skocak-3959 .skocak-element.skocak-element-f4a4b0d {
        width: 64%
    }

    .skocak-3959 .skocak-element.skocak-element-35f2041 {
        width: 18%
    }
}

.skocak-1763 .skocak-element.skocak-element-a96b52e {
    --spacer-size: 23px
}

.skocak-1763 .skocak-element.skocak-element-50321b5 {
    --spacer-size: 19px
}

.sc_blogger .type-product .sc_blogger_item_price {
    color: var(--theme-color-extra_light) !important
}

.sc_blogger .type-product .sc_blogger_item_price del {
    display: inline-block;
    margin-right: 6px;
    opacity: 1;
    text-decoration: none;
    position: relative;
    color: inherit
}

.sc_blogger .type-product .sc_blogger_item_price del:before {
    content: ' ';
    position: absolute;
    z-index: 1;
    top: 45%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--theme-color-extra_light)
}

@media (max-width: 1279px) {
    .sc_layouts_cart_widget {
        right: -1.7em
    }
}

@media (max-width: 767px) {
    .sc_layouts_cart_widget {
        max-height: 100vh;
        padding: 50px 20px
    }

    .sc_layouts_cart_widget {
        right: 0;
        top: 0
    }
}

@media (max-width: 767px) and (orientation: landscape) {
    .sc_layouts_cart_widget {
        padding: 40px 30px
    }
}

@media (max-width: 1439px) {
    .sc_layouts_row .sc_layouts_logo img {
        max-height: 4.5em
    }

    .sc_layouts_row_type_compact .sc_layouts_logo img {
        max-height: 3.5em
    }
}

@media (max-width: 1279px) {
    :root {
        --sc-space-tiny: 1em;
        --sc-space-small: 2em;
        --sc-space-medium: 3em;
        --sc-space-large: 4em;
        --sc-space-huge: 5em;
        --sc-shift-tiny: 1em;
        --sc-shift-small: 2em;
        --sc-shift-medium: 3em;
        --sc-shift-large: 4em
    }
}

@media (max-width: 1023px) {
    .sc_layouts_column .sc_layouts_item:not(.skocak-widget-divider):not(.skocak-widget-spacer) {
        margin-right: 1.2em
    }

    .sc_layouts_column_align_right .sc_layouts_item:not(.skocak-widget-divider):not(.skocak-widget-spacer) {
        margin-left: 1.2em;
        margin-right: 0
    }

    .sc_layouts_column_align_center .sc_layouts_item:not(.skocak-widget-divider):not(.skocak-widget-spacer) {
        margin-left: .6em;
        margin-right: .6em
    }

    .sc_layouts_menu_dir_horizontal .sc_layouts_menu_nav > li > a {
        padding: .7em
    }
}

@media (max-width: 767px) {
    .trx_addons_columns_wrap {
        margin-right: -20px
    }

    .trx_addons_columns_wrap > [class*=trx_addons_column-] {
        padding-right: 20px
    }

    .trx_addons_columns_wrap.columns_padding_bottom > [class*=trx_addons_column-] {
        padding-bottom: 20px
    }

    .trx_addons_columns_wrap.columns_padding_bottom.columns_in_single_row > [class*=trx_addons_column-] {
        padding-bottom: 20px
    }

    .trx_addons_columns_wrap:not(.columns_fluid) > [class*=trx_addons_column-] {
        width: 50%
    }

    .sc_item_filters_align_right {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-bottom: none;
        padding-bottom: 0
    }

    .sc_item_filters_align_right .sc_item_filters_tabs {
        margin-bottom: 1em !important
    }

    .sc_item_filters_align_right .sc_item_filters_tabs {
        max-width: none
    }

    .sc_item_filters .sc_item_filters_tabs {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        width: 100%
    }

    .sc-mobile_layouts_column_align_left .skocak-widget-wrap {
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .sc-mobile_layouts_column_align_left .sc_layouts_item:not(.skocak-widget-divider):not(.skocak-widget-spacer) {
        margin-left: 0 !important;
        margin-right: 1.2em
    }

    .sc-mobile_layouts_column_align_left .sc_layouts_item:not(.skocak-widget-divider):not(.skocak-widget-spacer):first-child {
        margin-left: 0 !important
    }

    .sc-mobile_layouts_column_align_left .sc_layouts_item:not(.skocak-widget-divider):not(.skocak-widget-spacer):last-child {
        margin-right: 0 !important
    }

    .sc-mobile_layouts_column_align_left {
        text-align: left
    }

    .sc_layouts_cart_widget {
        position: fixed;
        top: 46px;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
        height: auto
    }

    .sc_layouts_cart_widget:after {
        display: none
    }

    .sc_layouts_menu_mobile_button {
        display: inline-block !important
    }

    .sc_layouts_menu_mobile_button + .sc_layouts_menu {
        display: none !important
    }
}

@media (max-width: 600px) {
    .sc_layouts_cart_widget {
        top: 0
    }
}

@media (max-width: 479px) {
    .trx_addons_columns_wrap {
        margin-right: -20px
    }

    .trx_addons_columns_wrap > [class*=trx_addons_column-] {
        padding-right: 20px
    }

    .trx_addons_columns_wrap.columns_padding_bottom > [class*=trx_addons_column-] {
        padding-bottom: 20px
    }

    .trx_addons_columns_wrap.columns_padding_bottom.columns_in_single_row > [class*=trx_addons_column-] {
        padding-bottom: 20px
    }

    .trx_addons_columns_wrap:not(.columns_fluid) > [class*=trx_addons_column-] {
        width: 100%
    }
}

@media (max-width: 600px) {
    .sc_blogger_item_wide, .sc_blogger_item_wide.sc_blogger_item_with_image .sc_blogger_item_body {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column
    }

    .sc_blogger_item_wide .sc_blogger_item_body, .sc_blogger_item_wide .sc_blogger_item_content, .sc_blogger_item_wide .sc_blogger_item_featured {
        width: 100% !important;
        padding: 0 !important
    }

    .sc_blogger_item_wide .sc_blogger_item_body {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .sc_blogger_item_wide .sc_blogger_item_featured {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .sc_blogger_item_wide .sc_blogger_item_content {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        margin-top: 1.5em
    }
}

@media (max-width: 479px) {
    .widget_instagram_images_columns_3 .widget_instagram_images_item_wrap {
        width: 50%
    }
}

@media (max-width: 1679px) {
    :root {
        --theme-var-size-koef: calc(1440 / 1680);
        --theme-var-page: min(1290px, var(--theme-var-page_width));
        --theme-var-sidebar: clamp(var(--theme-var-sidebar_width_min, 150px), calc(var(--theme-var-page) * var(--theme-var-sidebar_prc) * var(--theme-var-sidebar_proportional) + var(--theme-var-sidebar_width) * (1 - var(--theme-var-sidebar_proportional))), var(--theme-var-sidebar_width_max, 500px));
        --theme-var-sidebar_gap: clamp(var(--theme-var-sidebar_gap_width_min, 0px), calc(var(--theme-var-page) * var(--theme-var-sidebar_gap_prc) * var(--theme-var-sidebar_proportional) + var(--theme-var-sidebar_gap_width) * (1 - var(--theme-var-sidebar_proportional))), var(--theme-var-sidebar_gap_width_max, 100px));
        --theme-var-sidebar_and_gap: calc(var(--theme-var-sidebar) + var(--theme-var-sidebar_gap));
        --theme-var-content: calc(var(--theme-var-page) - var(--theme-var-sidebar) - var(--theme-var-sidebar_gap));
        --theme-var-content_narrow: calc(var(--theme-var-content) * var(--theme-var-koef_narrow));
        --theme-var-padding_narrow: calc(var(--theme-var-content) * (1 - var(--theme-var-koef_narrow)))
    }
}

@media (max-width: 1439px) {
    :root {
        --theme-var-page_width: 1100px;
        --theme-var-page: var(--theme-var-page_width);
        --theme-var-sidebar: clamp(var(--theme-var-sidebar_width_min, 150px), calc(var(--theme-var-page) * var(--theme-var-sidebar_prc) * var(--theme-var-sidebar_proportional) + var(--theme-var-sidebar_width) * (1 - var(--theme-var-sidebar_proportional))), var(--theme-var-sidebar_width_max, 500px));
        --theme-var-sidebar_gap: clamp(var(--theme-var-sidebar_gap_width_min, 0px), calc(var(--theme-var-page) * var(--theme-var-sidebar_gap_prc) * var(--theme-var-sidebar_proportional) + var(--theme-var-sidebar_gap_width) * (1 - var(--theme-var-sidebar_proportional))), var(--theme-var-sidebar_gap_width_max, 100px));
        --theme-var-sidebar_and_gap: calc(var(--theme-var-sidebar) + var(--theme-var-sidebar_gap));
        --theme-var-content: calc(var(--theme-var-page) - var(--theme-var-sidebar) - var(--theme-var-sidebar_gap));
        --theme-var-content_narrow: calc(var(--theme-var-content) * var(--theme-var-koef_narrow));
        --theme-var-padding_narrow: calc(var(--theme-var-content) * (1 - var(--theme-var-koef_narrow)));
        --theme-var-page_column: calc((var(--theme-var-page_narrow_width) - (11 * var(--theme-var-grid_gap))) / 12);
        --theme-var-page_column_and_gap: calc(var(--theme-var-page_column) + var(--theme-var-grid_gap));
        --theme-var-content_narrow: calc(var(--theme-var-page_column) * 6 + 5 * var(--theme-var-grid_gap));
        --theme-var-padding_narrow: calc((var(--theme-var-page_narrow_width) - var(--theme-var-content_narrow)) / 2)
    }

    .sc_blogger_item_default.sc_blogger_item_image_position_top.sc_blogger_item_align_center .post_featured + .sc_blogger_item_content {
        max-width: 100%
    }
}

@media (max-width: 1279px) {
    :root {
        --theme-var-page_extra: 50px;
        --theme-var-page: calc(100vw - var(--theme-var-page_extra) * 2);
        --theme-var-sidebar: clamp(var(--theme-var-sidebar_width_min, 150px), calc(var(--theme-var-page) * var(--theme-var-sidebar_prc) * var(--theme-var-sidebar_proportional) + var(--theme-var-sidebar_width) * (1 - var(--theme-var-sidebar_proportional))), var(--theme-var-sidebar_width_max, 500px));
        --theme-var-sidebar_gap: clamp(var(--theme-var-sidebar_gap_width_min, 0px), calc(var(--theme-var-page) * var(--theme-var-sidebar_gap_prc) * var(--theme-var-sidebar_proportional) + var(--theme-var-sidebar_gap_width) * (1 - var(--theme-var-sidebar_proportional))), var(--theme-var-sidebar_gap_width_max, 100px));
        --theme-var-sidebar_and_gap: calc(var(--theme-var-sidebar) + var(--theme-var-sidebar_gap));
        --theme-var-content: calc(var(--theme-var-page) - var(--theme-var-sidebar) - var(--theme-var-sidebar_gap));
        --theme-var-content_narrow: calc(var(--theme-var-content) * var(--theme-var-koef_narrow));
        --theme-var-padding_narrow: calc(var(--theme-var-content) * (1 - var(--theme-var-koef_narrow)));
        --theme-var-page_column: calc((var(--theme-var-page_narrow_width) - (11 * var(--theme-var-grid_gap))) / 12);
        --theme-var-page_column_and_gap: calc(var(--theme-var-page_column) + var(--theme-var-grid_gap));
        --theme-var-content_narrow: calc(var(--theme-var-page_column) * 6 + 5 * var(--theme-var-grid_gap));
        --theme-var-padding_narrow: calc((var(--theme-var-page_narrow_width) - var(--theme-var-content_narrow)) / 2)
    }

    .sc_item_filters_tabs > li > a {
        -webkit-transition: background-size .4s linear, color .3s linear;
        -o-transition: background-size .4s linear, color .3s linear;
        transition: background-size .4s linear, color .3s linear
    }

    .menu_mobile .menu_mobile_nav_area > ul > li ul > li > a span:not(.open_child_menu), .sc_layouts_menu_dir_horizontal .sc_layouts_menu_nav > li ul > li > a span {
        -webkit-transition: background-size .4s linear;
        -o-transition: background-size .4s linear;
        transition: background-size .4s linear
    }

    .sc_blogger .sc_blogger_item .post_featured + .sc_blogger_item_content .sc_blogger_item_title a, .sc_blogger .sc_blogger_item .sc_item_featured [class*=post_info_] .entry-title a, .sc_blogger .sc_blogger_item.sc_blogger_item_default .sc_blogger_item_content .sc_blogger_item_title a {
        -webkit-transition: background-size .8s linear;
        -o-transition: background-size .8s linear;
        transition: background-size .8s linear
    }

    .post_featured.hover_shop .icons a {
        top: 0;
        -webkit-animation-name: none;
        animation-name: none
    }
}

@media (min-width: 768px) and (max-width: 1279px) {
    .sc_blogger_default_classic .trx_addons_column-1_4 .sc_blogger_title_tag_h5.sc_blogger_item_with_image.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content {
        margin-top: 13px
    }

    .sc_blogger_default_classic .trx_addons_column-1_4 .sc_blogger_title_tag_h5.sc_blogger_item_with_image .sc_blogger_item_title {
        font-size: 18px;
        line-height: 22px
    }
}

@media (max-width: 1023px) {
    :root {
        --theme-var-page_extra: 30px;
        --theme-var-page: calc(100vw - var(--theme-var-page_extra) * 2);
        --theme-var-sidebar: clamp(var(--theme-var-sidebar_width_min, 150px), calc(var(--theme-var-page) * var(--theme-var-sidebar_prc) * var(--theme-var-sidebar_proportional) + var(--theme-var-sidebar_width) * (1 - var(--theme-var-sidebar_proportional))), var(--theme-var-sidebar_width_max, 500px));
        --theme-var-sidebar_gap: clamp(var(--theme-var-sidebar_gap_width_min, 0px), calc(var(--theme-var-page) * var(--theme-var-sidebar_gap_prc) * var(--theme-var-sidebar_proportional) + var(--theme-var-sidebar_gap_width) * (1 - var(--theme-var-sidebar_proportional))), var(--theme-var-sidebar_gap_width_max, 100px));
        --theme-var-sidebar_and_gap: calc(var(--theme-var-sidebar) + var(--theme-var-sidebar_gap));
        --theme-var-content: var(--theme-var-page);
        --theme-var-content_narrow: calc(var(--theme-var-content) * var(--theme-var-koef_narrow));
        --theme-var-padding_narrow: calc(var(--theme-var-content) * (1 - var(--theme-var-koef_narrow)));
        --theme-var-page_column: calc((var(--theme-var-page_narrow_width) - (11 * var(--theme-var-grid_gap))) / 12);
        --theme-var-page_column_and_gap: calc(var(--theme-var-page_column) + var(--theme-var-grid_gap));
        --theme-var-content_narrow: calc(var(--theme-var-page_column) * 6 + 5 * var(--theme-var-grid_gap));
        --theme-var-padding_narrow: calc((var(--theme-var-page_narrow_width) - var(--theme-var-content_narrow)) / 2)
    }

    .menu_mobile .menu_mobile_nav_area {
        position: relative;
        top: auto;
        margin: 3em 0 0;
        max-height: none;
        padding-right: 0;
        overflow-y: visible;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }

    .menu_mobile .menu_mobile_inner {
        overflow-y: auto
    }

    .menu_mobile .sc_layouts_logo {
        margin-top: 4em
    }

    body {
        font-size: 15px
    }

    h2 {
        font-size: 28px;
        line-height: 31px
    }

    h3 {
        font-size: 24px;
        line-height: 26px
    }

    h4 {
        font-size: 22px;
        line-height: 26px
    }

    h5 {
        font-size: 20px;
        line-height: 23px
    }

    .sc_button:not(.sc_button_simple):not(.sc_button_rn):not(.sc_button_simple):not(.sc_button_rounded):not(.sc_button_rn) {
        padding-right: 23px;
        padding-left: 24px
    }

    .sc_button.sc_button_size_small:not(.sc_button_simple):not(.sc_button_rounded):not(.sc_button_rn) {
        padding-right: 17px;
        padding-left: 18px
    }

    header .sc_button.sc_button_size_small:not(.sc_button_simple):not(.sc_button_rounded):not(.sc_button_rn) {
        padding-right: 17px;
        padding-left: 18px
    }

    .sc_button_icon_left .sc_button_icon {
        margin-right: 5px
    }

    .sc_button_icon {
        font-size: 17px;
        height: 18px;
        line-height: 19px;
        width: 18px
    }

    .sc_blogger_item:not(.sc_blogger_item_default_classic_2) .sc_item_featured [class*=post_info_] {
        padding: 30px !important;
        -webkit-box-sizing: border-box;
        box-sizing: border-box
    }

    .sc_blogger_item_button:not(.with_text) {
        margin-bottom: -5px
    }

    .sc_blogger .sc_blogger_item_price {
        font-size: 17px
    }

    .sc_blogger .sc_blogger_title_tag_h4 .sc_blogger_item_excerpt + .sc_blogger_item_button:not(.with_text) {
        margin-top: 9px
    }

    .sc_layouts_menu_nav {
        font-size: 14px
    }

    .sc_layouts_menu_dir_horizontal .sc_layouts_menu_nav > li > a {
        padding: .7em
    }

    .post_meta {
        font-size: 12px;
        line-height: 18px
    }

    .pk-social-links-wrap.pk-social-links-template-default .pk-social-links-items {
        margin: 0 -.3rem 0 0
    }

    .pk-social-links-wrap.pk-social-links-template-default .pk-social-links-item {
        padding: 0 .3rem 0 0
    }

    h2.sc_item_title {
        letter-spacing: -.03em
    }

    .post_featured.hover_shop .icons a {
        width: 42px !important;
        height: 42px !important;
        line-height: 42px !important
    }
}

@media (max-width: 767px) {
    :root {
        --theme-var-page_extra: 30px;
        --theme-var-page: calc(100vw - var(--theme-var-page_extra) * 2);
        --theme-var-grid_gap: 20px;
        --theme-var-grid_max_columns: 2;
        --theme-var-sidebar: clamp(var(--theme-var-sidebar_width_min, 150px), calc(var(--theme-var-page) * var(--theme-var-sidebar_prc) * var(--theme-var-sidebar_proportional) + var(--theme-var-sidebar_width) * (1 - var(--theme-var-sidebar_proportional))), var(--theme-var-sidebar_width_max, 500px));
        --theme-var-sidebar_gap: clamp(var(--theme-var-sidebar_gap_width_min, 0px), calc(var(--theme-var-page) * var(--theme-var-sidebar_gap_prc) * var(--theme-var-sidebar_proportional) + var(--theme-var-sidebar_gap_width) * (1 - var(--theme-var-sidebar_proportional))), var(--theme-var-sidebar_gap_width_max, 100px));
        --theme-var-sidebar_and_gap: calc(var(--theme-var-sidebar) + var(--theme-var-sidebar_gap));
        --theme-var-content: var(--theme-var-page);
        --theme-var-content_narrow: var(--theme-var-content);
        --theme-var-padding_narrow: 0px
    }

    .trx_addons_columns_wrap.columns_padding_bottom.columns_in_single_row > [class*=trx_addons_column-] {
        padding-bottom: var(--theme-var-grid_gap)
    }

    .trx_addons_columns_wrap.columns_padding_bottom.columns_in_single_row {
        margin-bottom: calc(-1 * var(--theme-var-grid_gap))
    }

    .trx_addons_columns_wrap:not(.columns_fluid) > [class*=trx_addons_column-] {
        width: calc(100% / var(--theme-var-grid_max_columns))
    }


    ul {
        margin-bottom: 1.3em
    }

    .sc_blogger_default .trx_addons_columns_wrap.columns_padding_bottom {
        margin-bottom: -26px
    }

    .sc_blogger_default .trx_addons_columns_wrap.columns_padding_bottom > [class*=trx_addons_column-] {
        padding-bottom: 26px
    }

    .sc_item_filters_tabs li {
        padding: 0 .6em !important
    }

    .sc_item_filters_align_right .sc_item_filters_tabs {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        margin-bottom: 0 !important
    }

    .sc_layouts_menu_mobile_button {
        display: block !important
    }

    .sc_blogger_default_over_bottom_center .slider_container.slider_pagination_bullets.slider_pagination_pos_bottom + .swiper-pagination-bullets, .slider_container.slider_pagination_bullets.slider_pagination_pos_bottom + .swiper-pagination-bullets {
        bottom: 15px
    }

    .sc_item_title_wrap + .sc_item_content {
        margin-top: 1.7em
    }

    .skocak-sidebar .skocak-widget + .skocak-widget {
        margin-top: 3.25rem;
        padding-top: 0;
        border-top: none
    }

    .post_featured.hover_shop .icons a {
        width: 3rem !important;
        height: 3rem !important
    }

    :root {
        --theme-var-elm_gap_narrow: 8px;
        --theme-var-elm_gap_default: 10px;
        --theme-var-elm_gap_extended: var(--theme-var-grid_gap);
        --theme-var-elm_gap_wide: 30px;
        --theme-var-elm_gap_wider: 40px;
        --theme-var-elm_add_page_margins: var(--theme-var-elm_gap_extended)
    }
}

@media (max-width: 600px) {
    :root {
        --theme-var-grid_gap: 20px;
        --theme-var-grid_max_columns: 1
    }

    .trx_addons_columns_wrap:not(.columns_fluid) > [class*=trx_addons_column-] {
        width: calc(100% / var(--theme-var-grid_max_columns))
    }

    .sc_blogger_content .sc_blogger_item_wide:not(.post_format_image[class*=sc_blogger_item_info_over]) + .sc_blogger_item_wide {
        margin-top: 25px;
        padding-top: 30px
    }

    .sc_blogger_wide .sc_blogger_item_wide.sc_blogger_title_tag_h4 .sc_blogger_item_featured + .sc_blogger_item_content {
        margin-top: 20px
    }
}

@media (max-width: 479px) {
    :root {
        --theme-var-page_extra: 20px;
        --theme-var-page: calc(100vw - var(--theme-var-page_extra) * 2);
        --theme-var-grid_gap: 20px;
        --theme-var-grid_max_columns: 1;
        --theme-var-sidebar: clamp(var(--theme-var-sidebar_width_min, 150px), calc(var(--theme-var-page) * var(--theme-var-sidebar_prc) * var(--theme-var-sidebar_proportional) + var(--theme-var-sidebar_width) * (1 - var(--theme-var-sidebar_proportional))), var(--theme-var-sidebar_width_max, 500px));
        --theme-var-sidebar_gap: clamp(var(--theme-var-sidebar_gap_width_min, 0px), calc(var(--theme-var-page) * var(--theme-var-sidebar_gap_prc) * var(--theme-var-sidebar_proportional) + var(--theme-var-sidebar_gap_width) * (1 - var(--theme-var-sidebar_proportional))), var(--theme-var-sidebar_gap_width_max, 100px));
        --theme-var-sidebar_and_gap: calc(var(--theme-var-sidebar) + var(--theme-var-sidebar_gap));
        --theme-var-content: var(--theme-var-page);
        --theme-var-content_narrow: var(--theme-var-content);
        --theme-var-padding_narrow: 0px
    }

    .trx_addons_columns_wrap:not(.columns_fluid) > [class*=trx_addons_column-] {
        width: calc(100% / var(--theme-var-grid_max_columns))
    }

    .menu_mobile .sc_layouts_logo {
        margin-top: 3em
    }

    .menu_mobile .menu_mobile_nav_area > ul > li > a {
        font-size: 18px;
        line-height: 1.5em
    }

    h2 {
        font-size: 26px;
        line-height: 29px
    }

    .sc_item_filters_align_right .sc_item_filters_tabs li {
        padding: .2em .6em
    }

    header .sc_button.sc_button_size_small {
        padding-right: 14px;
        padding-left: 15px
    }

    header .sc_button.sc_button_size_small .sc_button_icon {
        display: none
    }

    .sc_layouts_row_type_compact .sc_layouts_logo img {
        max-height: 45px
    }

    .pk-social-links-wrap .pk-social-links-items {
        margin-right: -2px
    }

    .pk-social-links-wrap .pk-social-links-item {
        padding-right: 2px
    }

    .pk-social-links-wrap .pk-social-links-label {
        font-size: 12px
    }

    .sc_title .sc_item_title_wrap .sc_item_title {
        width: 100%
    }

}

@media (min-width: 1024px) and (max-width: 1279px) {
    .trx_addons_column-1_3 .sc_blogger_item_default_classic_2.sc_blogger_title_tag_h3.sc_blogger_item_with_image.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content {
        margin-top: 24px
    }

    .trx_addons_column-1_3 .sc_blogger_item_default_classic_2.sc_blogger_title_tag_h3.sc_blogger_item_with_image .post_meta_categories {
        margin-bottom: 8px
    }

    .trx_addons_column-1_3 .sc_blogger_item_default_classic_2.sc_blogger_title_tag_h3.sc_blogger_item_with_image .sc_blogger_item_title {
        font-size: 26px;
        line-height: 28px
    }

    .trx_addons_column-1_3 .sc_blogger_item_default_classic_2.sc_blogger_title_tag_h3.sc_blogger_item_with_image .sc_blogger_item_title + .post_meta:not(.post_meta_categories) {
        margin-top: 10px
    }

    .sc_layouts_column .sc_layouts_item:not(.skocak-widget-divider):not(.skocak-widget-spacer) {
        margin-right: 1.6em
    }

    .sc_layouts_column_align_right .sc_layouts_item:not(.skocak-widget-divider):not(.skocak-widget-spacer) {
        margin-left: 1.6em;
        margin-right: 0
    }

    .pk-social-links-wrap .pk-social-links-items {
        margin-right: -5px
    }

    .pk-social-links-wrap .pk-social-links-item {
        padding-right: 5px
    }

    .pk-social-links-wrap.pk-social-links-scheme-rounded .pk-social-links-icon {
        width: 3.5rem;
        height: 3.5rem;
        line-height: 3.3rem
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .trx_addons_column-1_3 .sc_blogger_title_tag_h3.sc_blogger_item_with_image .sc_item_featured [class*=post_info_] .post_meta_categories {
        margin-top: 3px;
        margin-bottom: 14px
    }

    .trx_addons_column-1_3 .sc_blogger_item_default_classic_2.sc_blogger_title_tag_h3.sc_blogger_item_with_image.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content {
        margin-top: 23px
    }

    .trx_addons_column-1_3 .sc_blogger_item_default_classic_2.sc_blogger_title_tag_h3.sc_blogger_item_with_image .post_meta_categories {
        margin-bottom: 8px
    }
}

@media (max-width: 639px) {
    #sb_instagram.sbi_col_3 #sbi_images .sbi_item {
        width: 50%
    }

    .widget_instagram_images_item_wrap {
        min-width: 50%
    }
}

@media (min-width: 601px) and (max-width: 767px) {
    .skocak-sidebar .skocak-widget-wrap {
        margin-right: -30px;
        width: calc(100% + 30px)
    }

    .skocak-sidebar .skocak-widget-wrap > .skocak-element {
        width: calc(50% - 30px);
        margin-right: 30px
    }

    .skocak-sidebar .skocak-widget:nth-child(2) {
        margin-top: 0;
        padding-top: 0;
        border-top: 0
    }

    .pk-social-links-wrap .pk-social-links-items {
        margin-right: -2px
    }

    .pk-social-links-wrap .pk-social-links-item {
        padding-right: 2px
    }

    .pk-social-links-wrap .pk-social-links-label {
        font-size: 12px
    }

    .pk-social-links-wrap.pk-social-links-scheme-rounded .pk-social-links-icon {
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
        text-align: center
    }
}

@-webkit-keyframes animation_back_down {
    0% {
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px)
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-moz-keyframes animation_back_down {
    0% {
        transform: translateY(-3px)
    }
    100% {
        transform: translateY(0)
    }
}

@keyframes animation_back_down {
    0% {
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px)
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes animation_jump_up {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    100% {
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px)
    }
}

@-moz-keyframes animation_jump_up {
    0% {
        transform: translateY(0)
    }
    100% {
        transform: translateY(-3px)
    }
}

@keyframes animation_jump_up {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    100% {
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px)
    }
}

@-webkit-keyframes animation_fade_in_up {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1;
        top: 0
    }
}

@-moz-keyframes animation_fade_in_up {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1;
        top: 0
    }
}

@keyframes animation_fade_in_up {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1;
        top: 0
    }
}

.banner-image {
    height: 240px !important;
    width: 100%;
    object-fit: cover;
}

.banner-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, white 0%, transparent 50%),  linear-gradient(to top, white 0%, transparent 50%),linear-gradient(to left, white 0%, transparent 50%);
    pointer-events: none;
}

.header-social-container a {
    width: 50px;
    height: 50px;
    justify-content: center;
    border-radius: 50px;
    border : 1px solid lightgray;
    margin-left: 10px;
    margin-right: 10px;
}

.header-social-container a i {
    margin : auto !important;
}

.border-top-suggestion + .border-top-suggestion {
    border-top : 1px solid #d8e3e3;
}

.color-white {
    color : white !important;
}

.black-opacity-bg {
    background-color: rgba(0,0,0,0.2);
}

.category-holder {
    border-radius: 30px;
    font-size : 12px;
    color : black;
    border : 1px solid lightgray;
    display: inline-block;
    width: auto;
    padding : 5px 15px;
    margin : 5px;
    line-height: 18px;
    white-space : nowrap;
}

.shop-title-holder {
    background-color: white;
    top : -2px;
}

.shop-filter-holder {
    background-color: white;
    top : 45px;
}

.category-holder:hover, .category-holder.active {
    color : var(--theme-color-text_hover) !important;
    border-color: var(--theme-color-text_hover) !important;
}

input.category-holder {
    font-size : 16px;
    line-height: 30px;
}

input.category-holder:hover {
    color : black !important;
    border-color : lightgray !important;
}

.mr-0 {
    margin-right: 0 !important;
}

@media (max-width: 767px) {
    .sc_title.mb-5 {
        margin-bottom: 10px !important;
    }

    .sc_blogger_item_body.gap-4 {
        gap : 0 !important;
    }

    .footer-items {
        flex-direction: column-reverse;
    }

    .feature-item-container {
        margin-bottom: 20px;
    }

    .banner-image {
        height: 130px !important;
    }

    .banner-item-container {
        padding : 0 !important;
    }

    .banner-item-link-container {
        justify-content: center !important;
    }

    .page-wrapper-menu {
        margin-top : 0 !important;
    }

    .banner-image-overlay {
        background: linear-gradient(to right, white 0%, transparent 20%),  linear-gradient(to top, white 0%, transparent 20%), linear-gradient(to bottom, white 0%, transparent 20%),linear-gradient(to left, white 0%, transparent 20%);
    }

    .menu_mobile_inner {
        display: block;
        z-index: 999;
    }

    .mobile-menu-content {
        padding : 30px;
        padding-left : 15px;
    }

    .menu_desktop_inner {
        display:  none !important;
    }

    .sticky-xs-top {
        position: sticky !important;
        top : -2px;
        z-index: 9999;
    }

    .shop-title-holder {
        top : 55px;
    }

    .shop-filter-holder {
        top : 90px;
    }
}