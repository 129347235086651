.contact-input {
    border : 1px solid var(--theme-color-input_bd_color);
    background-color: var(--theme-color-input_bg_color);
    border-radius: 30px;
    padding : 10px 30px;
    font-size : 14px;
    font-family: Karla, sans-serif;
    transition: 200ms all ease;
}

.contact-input:hover, .contact-input:focus {
    border-color: var(--theme-color-input_bd_hover);
    background-color: var(--theme-color-input_bg_hover);
    transition: 200ms all ease;
}

textarea.contact-input {
    resize: none;
}

.contact-page-container .error {
    font-size: 12px !important;
    position: absolute;
    bottom: -25px;
    left : 30px;
}

.contact-success-message {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background : rgba(255,255,255,0.9);
    top : 0;
    align-items: center;
    flex-direction: column;
}