.message-cell {
    width: 300px;
    max-width: 300px;
}

.ellipsis-cell{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
    word-break: break-all !important;
}