.page-wrapper-menu {
    margin-top: -70px;
}

.mobile-menu-content ul {
    list-style: none !important;
}

.mobile-menu-content ul li {
    padding-bottom: 15px !important;
}

.mobile-menu-content ul a {

    font-size: 18px;
    font-family: var(--theme-font-button_font-family);
    font-weight: 700;
}

.mobile-menu-content ul a:hover, .mobile-menu-content li.current-menu-ancestor a {
    color: var(--theme-color-text_link) !important;
}

.menu_mobile_close {
    font-size: 30px;
    color : rgb(90,90,90);
    position: absolute;
    top : 20px;
    right: 20px;
}

.mobile-menu-holder {
    background-color: white;
    padding-bottom: 10px;
}

.active-page-mobile {
    font-size : 18px;
    font-weight: normal;
    text-overflow: ellipsis;
    width: 80%;
    overflow: hidden;
    word-wrap: initial;
}