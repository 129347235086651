
.funfact__item {
    position: relative;
    display: flex;
    gap: 15px;
}

.funfact__icon-box {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: var(--acn-theme-black5, #E6B088);
    transition: all 500ms ease;
}

.funfact__icon {
    display: inline-flex;
    font-size: 20px;
    color: var(--acn-theme-white, #ffffff);
    transition: all 500ms ease;
}
.funfact__icon svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
}
.funfact__item:hover .funfact__icon {
    -webkit-animation-name: wobble-horizontal-hover;
    animation-name: wobble-horizontal-hover;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}
.funfact__content {
    margin-top: 6px;
}
.funfact__count {
    margin-top : 0!important;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
}
.funfact__text {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
}

.funfact__row {
    row-gap: 30px;
    flex-wrap: wrap;
}
