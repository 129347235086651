
.service-card-three {
    position: relative;
    padding: 9px 10px;
    background-color: var(--acn-theme-white, #ffffff);
    border-radius: 30px;
    filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.05));
    overflow: hidden;
    height: 100%;
}

.service-card-three::after {
    content: "";
    width: 100%;
    height: 0%;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: var(--acn-theme-base, #E6B088FF);
    border-radius: inherit;
    transition: all 500ms ease;
}

.service-card-three:hover::after {
    height: 100%;
    top: 0;
}

.service-card-three__image {
    position: relative;
    z-index: 2;
}

.service-card-three__image img {
    width: 100%;
    display: block;
    border-radius: 30px 30px 0 0;
}

.service-card-three__content {
    position: relative;
    padding: 0 21px 10px;
    text-align: center;
    border-top: 4px solid var(--acn-theme-base, #E6B088FF);
    z-index: 2;
    transition: all 500ms ease;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .service-card-three__content {
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media (max-width: 360px) {
    .service-card-three__content {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.service-card-three:hover .service-card-three__content {
    border-color: var(--acn-theme-white, #ffffff);
}

.service-card-three__icon-box {
    margin: 0 auto 23px;
    width: 60px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--acn-theme-base, #E6B088FF);
    -webkit-mask: url('data:image/svg+xml;utf8,<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270.000000 210.000000"><g transform="translate(0.000000,210.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M4 2038 c10 -94 21 -176 34 -245 7 -35 17 -91 24 -125 6 -35 14 -88 18 -118 4 -30 12 -83 18 -117 7 -35 17 -91 24 -125 6 -35 14 -88 18 -118 4 -30 12 -83 18 -117 7 -35 17 -91 24 -125 6 -35 14 -88 18 -118 4 -30 12 -84 19 -120 7 -36 17 -90 22 -120 6 -30 14 -86 19 -125 5 -38 14 -86 20 -105 5 -19 10 -56 10 -82 0 -62 48 -166 97 -208 20 -18 56 -41 80 -51 43 -18 81 -19 874 -19 l830 0 54 25 c95 45 153 135 165 255 4 36 12 93 18 128 31 162 36 191 42 242 4 30 12 83 18 117 7 35 17 91 24 125 6 35 14 88 18 118 4 30 12 83 18 118 7 34 17 90 24 125 6 34 14 87 18 117 4 30 12 83 18 118 7 34 17 90 24 125 6 34 14 87 18 117 4 30 12 82 18 115 7 33 14 81 18 108 3 32 10 47 20 47 10 0 14 13 14 50 l0 50 -1351 0 -1352 0 7 -62z"/> </g> </svg>');
    mask: url('data:image/svg+xml;utf8,<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270.000000 210.000000"><g transform="translate(0.000000,210.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M4 2038 c10 -94 21 -176 34 -245 7 -35 17 -91 24 -125 6 -35 14 -88 18 -118 4 -30 12 -83 18 -117 7 -35 17 -91 24 -125 6 -35 14 -88 18 -118 4 -30 12 -83 18 -117 7 -35 17 -91 24 -125 6 -35 14 -88 18 -118 4 -30 12 -84 19 -120 7 -36 17 -90 22 -120 6 -30 14 -86 19 -125 5 -38 14 -86 20 -105 5 -19 10 -56 10 -82 0 -62 48 -166 97 -208 20 -18 56 -41 80 -51 43 -18 81 -19 874 -19 l830 0 54 25 c95 45 153 135 165 255 4 36 12 93 18 128 31 162 36 191 42 242 4 30 12 83 18 117 7 35 17 91 24 125 6 35 14 88 18 118 4 30 12 83 18 118 7 34 17 90 24 125 6 34 14 87 18 117 4 30 12 83 18 118 7 34 17 90 24 125 6 34 14 87 18 117 4 30 12 82 18 115 7 33 14 81 18 108 3 32 10 47 20 47 10 0 14 13 14 50 l0 50 -1351 0 -1352 0 7 -62z"/> </g> </svg>');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-size: auto;
    mask-size: auto;
    transition: all 500ms ease;
}

.service-card-three:hover .service-card-three__icon-box {
    background-color: var(--acn-theme-white, #ffffff);
}

.service-card-three__icon {
    font-size: 20px;
    color: var(--acn-theme-white, #ffffff);
    transition: all 500ms ease;
}

.service-card-three__icon svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
}

.service-card-three:hover .service-card-three__icon {
    color: var(--acn-theme-base, #E6B088FF);
    -webkit-animation-name: wobble-horizontal-hover;
    animation-name: wobble-horizontal-hover;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

.service-card-three__title {
    margin-bottom: 7px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.318;
    text-transform: capitalize;
}

.service-card-three__title a {
    color: inherit;
    transition: all 500ms ease;
}

.service-card-three__title a:hover {
    background-size: 100% 1px;
    color : white !important;
}

.service-card-three__text {
    margin-bottom: 6px;
    transition: all 500ms ease;
}

.service-card-three__link {
    display: inline-flex;
    align-items: center;
    gap: 2px;
    font-weight: 500;
}

.service-card-three:hover .service-card-three__title, .service-card-three:hover .service-card-three__text, .service-card-three:hover .service-card-three__link {
    color: var(--acn-theme-white, #ffffff);
}