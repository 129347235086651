.slider-image {
    width: auto !important;
    display: inline-block !important;
    max-width: 100%;
    height: auto;
}

.main-slider-two {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

@media (max-width: 1399px) {
    .main-slider-two__carousel.owl-carousel .owl-nav {
        margin-top: 0;
        position: absolute;
        left: 50%;
        bottom: 220px;
        transform: translateX(-50%);
    }
}

@media (max-width: 767px) {
    .main-slider-two__carousel.owl-carousel .owl-nav {
        bottom: 100px;
    }
}

.main-slider-two__carousel.owl-carousel .owl-nav button {
    color: var(--acn-theme-white, #ffffff);
    background-color: transparent;
    border: 1px solid var(--acn-theme-white, #ffffff);
}

@media (min-width: 1400px) {
    .main-slider-two__carousel.owl-carousel .owl-nav button.owl-prev, .main-slider-two__carousel.owl-carousel .owl-nav button.owl-next {
        position: absolute;
        top: calc(50% - 62px);
        transform: translateY(-50%);
    }
}

.main-slider-two__carousel.owl-carousel .owl-nav button.owl-prev {
    left: 25px;
}

@media (min-width: 1200px) {
    .main-slider-two__carousel.owl-carousel .owl-nav button.owl-prev {
        left: 100px;
    }
}

.main-slider-two__carousel.owl-carousel .owl-nav button.owl-next {
    right: 25px;
}

@media (min-width: 1200px) {
    .main-slider-two__carousel.owl-carousel .owl-nav button.owl-next {
        right: 100px;
    }
}

.main-slider-two__carousel.owl-carousel .owl-nav button:hover {
    border-color: var(--acn-theme-base, #E6B088);
}

.main-slider-two__item {
    position: relative;
    padding-top: 31.25%;
    overflow: hidden;
}

.main-slider-two__item.slider-mobile {
    padding-top: 100%;
}

.main-slider-two__item.slider-mobile .main-slider-two__content{
    padding : 50px !important;
    zoom: 1.8;
}


.main-slider-two__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--acn-theme-black5, #797979);
    -webkit-mask: url('data:image/svg+xml;utf8,<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900.000000 390.000000"><g transform="translate(0.000000,390.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M0 2024 c0 -1776 1 -1878 18 -1890 34 -27 143 -72 222 -94 92 -25 171 -32 400 -37 228 -5 356 3 495 29 33 6 83 14 110 18 28 4 73 13 100 20 28 6 76 18 108 26 84 20 176 44 227 59 41 12 71 20 200 56 25 7 63 15 85 19 22 4 60 12 85 18 127 31 317 59 457 69 95 6 285 -15 368 -41 22 -7 67 -21 100 -31 33 -9 76 -23 95 -30 45 -16 121 -35 190 -48 36 -7 1041 -11 2898 -14 l2842 -3 0 1875 0 1875 -4500 0 -4500 0 0 -1876z"/> </g> </svg>');
    mask: url('data:image/svg+xml;utf8,<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900.000000 390.000000"><g transform="translate(0.000000,390.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M0 2024 c0 -1776 1 -1878 18 -1890 34 -27 143 -72 222 -94 92 -25 171 -32 400 -37 228 -5 356 3 495 29 33 6 83 14 110 18 28 4 73 13 100 20 28 6 76 18 108 26 84 20 176 44 227 59 41 12 71 20 200 56 25 7 63 15 85 19 22 4 60 12 85 18 127 31 317 59 457 69 95 6 285 -15 368 -41 22 -7 67 -21 100 -31 33 -9 76 -23 95 -30 45 -16 121 -35 190 -48 36 -7 1041 -11 2898 -14 l2842 -3 0 1875 0 1875 -4500 0 -4500 0 0 -1876z"/> </g> </svg>');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.main-slider-two__image {
    width: 100%;
    height: calc(100% - 33px);
    position: absolute;
    top: 0;
    right: 0;
}

.main-slider-two__image__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    z-index: 1;
    -webkit-mask: url('data:image/svg+xml;utf8,<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 206.000000"><g transform="translate(0.000000,206.000000) scale(0.050000,-0.050000)" stroke="none"> <path d="M649 4047 c-245 -267 -203 -480 175 -882 270 -287 430 -513 509 -717 207 -536 -152 -1295 -1018 -2153 l-298 -295 2992 0 2991 0 0 2060 0 2060 -2642 0 -2643 0 -66 -73z"/> </g> </svg>');
    mask: url('data:image/svg+xml;utf8,<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 206.000000"><g transform="translate(0.000000,206.000000) scale(0.050000,-0.050000)" stroke="none"> <path d="M649 4047 c-245 -267 -203 -480 175 -882 270 -287 430 -513 509 -717 207 -536 -152 -1295 -1018 -2153 l-298 -295 2992 0 2991 0 0 2060 0 2060 -2642 0 -2643 0 -66 -73z"/> </g> </svg>');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.main-slider-two__image__bg__inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transform: scale(1);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: transform 7000ms ease;
}

.main-slider-two .container {
    position: relative;
    z-index: 2;
}

.main-slider-two__content {
    position: relative;
    z-index: 2;
}

@media (max-width: 1599px) {
    .main-slider-two__content {
        padding-right: 30px;
    }
}

@media (max-width: 1199px) {
    .main-slider-two__content {
        padding-right: 0px;
    }
}

@media (max-width: 767px) {
    .main-slider-two__item.slider-mobile .main-slider-two__content{
        padding : 15px !important;
    }
}

.main-slider-two__top {
    position: relative;
    display: table;
}

.main-slider-two__button__inner {
    overflow: hidden;
}

.main-slider-two__btn {
    color: var(--acn-theme-black4, #0f3564);
    background-color: var(--acn-theme-white, #ffffff);
}

.main-slider-two__btn::after {
    background-color: var(--acn-theme-base, #E6B088);
}

.main-slider-two__btn .acn-theme-btn__icon-box {
    background-color: var(--acn-theme-base, #E6B088);
}

.main-slider-two__btn:hover {
    color: var(--acn-theme-white, #ffffff);
}

.main-slider-two__btn:hover .acn-theme-btn__icon-box {
    background-color: var(--acn-theme-black3, #19232b);
}


p {
    margin: 0 !important;
}

br {
    margin: 0 !important;
    display: block;
    content: "";
}

