.product_title {
    font-family: var(--theme-font-h1_font-family)
}

.product_price {
    font-family: var(--theme-font-info_font-family);
    color: var(--theme-color-text_link) !important;
    font-size: 18px;
    font-weight: 700;
}

.product_description {
    font-family: var(--theme-font-p_font-family);
}

.buy-btn {
    font-family: var(--theme-font-info_font-family);
    background-color: rgb(230, 176, 136);
    color: white;
    border: 1px solid transparent;
    border-radius: 30px;
    padding: 14px 43px;
    font-size: 13px;
}

.buy-btn:hover {
    background-color: var(--theme-color-text_hover);
    color: white;
}

.description-title {
    font-family: var(--theme-font-h1_font-family);
    padding-bottom: 7px;
    padding-right: 20px;
    padding-left: 0;
    border-bottom: 1px solid rgb(169, 169, 169);
    font-size: 18px;
    font-weight: 500;
}

.description-description {
    font-family: var(--theme-font-p_font-family) !important;
}

