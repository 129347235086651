
.homepage-tabs li {
    cursor : pointer !important;
}

.homepage-tabs a {
    color : #ee9982 !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.homepage-tabs a.active {
    color : #ee9982 !important;
}

.page-header {
    background-color: var(--acn-theme-white2, #ecf2f2);
    position: relative;
    padding-top: 0px;
    padding-bottom: 25px;
    overflow: hidden;
}
@media (max-width: 767px) {
    .page-header {
        padding-bottom: 50px;
    }
}
@media (max-width: 575px) {
    .page-header {
        padding-top: 20px;
    }
}
.page-header__bg {
    position: relative;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.059;
}
.page-header .container {
    position: relative;
    z-index: 2;
}
.page-header__title {
    margin-bottom: 11px;
    font-size: 30px;
    color: var(--acn-theme-black5, #ee9982);
    font-weight: 700;
    text-transform: capitalize;
    line-height: 1.25;
}
@media (max-width: 1199px) {
    .page-header__title {
        font-size: 50px;
    }
}
@media (max-width: 767px) {
    .page-header__title {
        font-size: 40px;
    }
}
@media (max-width: 575px) {
    .page-header__title {
        font-size: 35px;
    }
}
@media (max-width: 390px) {
    .page-header__title {
        font-size: 30px;
    }
}
.page-header__image {
    max-width: 100%;
    height: auto;
    position: absolute;
    right: 217px;
    bottom: -90px;
    z-index: 1;
    animation: moveHorizontal 2s linear infinite;
}
@media (max-width: 1799px) {
    .page-header__image {
        right: 100px;
    }
}
@media (max-width: 1599px) {
    .page-header__image {
        max-width: 650px;
        right: 80px;
        bottom: -10px;
    }
}
@media (max-width: 1399px) {
    .page-header__image {
        max-width: 600px;
        right: 60px;
    }
}
@media (max-width: 1199px) {
    .page-header__image {
        display: none;
    }
}
.page-header__shape-one {
    max-width: 100%;
    height: auto;
    position: absolute;
    left: 73px;
    bottom: 0;
    animation: treeMove 6s ease infinite;
}
@media (max-width: 1799px) {
    .page-header__shape-one {
        max-width: 150px;
        left: 30px;
    }
}
@media (max-width: 390px) {
    .page-header__shape-one {
        max-width: 130px;
        left: 10px;
    }
}

.acn-theme-breadcrumb {
    margin: 0;
    display: inline-flex;
    align-items: center;
    gap: 8px 0;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.acn-theme-breadcrumb li {
    display: flex;
    align-items: center;
    font-size: 17px;
    color: var(--acn-theme-text, #636363);
    line-height: 1.25;
    font-weight: 400;
    text-transform: capitalize;
}
@media (max-width: 575px) {
    .acn-theme-breadcrumb li {
        font-size: 15px;
    }
}
.acn-theme-breadcrumb li:not(:last-of-type)::after {
    content: "/";
    margin-left: 5px;
    margin-right: 5px;
}
.acn-theme-breadcrumb li span,
.acn-theme-breadcrumb li a {
    display: inline-flex;
}
.acn-theme-breadcrumb li:not(:first-child) span {
    color: var(--acn-theme-base, #ee9982);
}
.acn-theme-breadcrumb li a {
    color: inherit;
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
    transition: all 500ms ease;
}
.acn-theme-breadcrumb li a:hover {
    background-size: 100% 1px;
}
.acn-theme-breadcrumb li a:hover {
    color: var(--acn-theme-base, #ee9982);
}








.management .table-responsive {
    position: relative;
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 10px;
}
@media (max-width: 1199px) {
    .management .table-responsive {
        margin-bottom: 30px;
    }
}
.management__table {
    position: relative;
    width: 100%;
    border: none;
    margin: 0 0 24px;
}
@media (max-width: 1199px) {
    .management__table {
        min-width: 1170px;
    }
}
.management__table thead tr {
    border-bottom: 1px solid var(--acn-theme-border-color, #dddddd);
}
.management__table thead tr th {

    font-size: 14px;
    color: var(--acn-theme-black8, #202221);
    line-height: 1;
    font-weight: 500;
    text-transform: capitalize;
    border: none;
    background-color: transparent;
    border: 0 !important;
    box-shadow: none;
}
.management__table thead tr th:first-child {
    padding-left: 0;
}
.management__table thead tr th:last-child {
    padding-right: 0;
    text-align: right;
}
.management__table tbody tr td {
    font-size: 13px;
    font-weight: 400;
    color: var(--acn-theme-base, #202221);
    line-height: normal;
    vertical-align: middle;
    border-top: none;
    box-shadow: none;
    background-color: transparent;
    border-top: 0 !important;
    border-bottom: 1px solid var(--acn-theme-border-color, #dddddd);
    letter-spacing: 0;
}
.management__table tbody tr td.management__table__price, .management__table tbody tr td.management__table__total {
    font-size: 16px;
    font-weight: 500;
    color: var(--acn-theme-text, #636363);
}
.management__table tbody tr td.management__table__total {
    text-align: center;
}
.management__table tbody tr td .management__table__remove {
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--acn-theme-text, #636363);
    font-size: 16px;
    line-height: normal;
}
.management__table tbody tr td .management__table__remove svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
}
.management__table tbody tr td .management__table__remove:hover {
    color: var(--acn-theme-base, #0365d3);
}
.management__table tbody tr td:first-child {
    padding-left: 0;
}
.management__table tbody tr td:last-child {
    padding-right: 0;
}
.management__table .product-details__quantity {
    margin-bottom: 0;
}
.management__table .product-details__quantity .quantity-box input {
    color: var(--acn-theme-base, #0365d3);
}
.management__table__meta {
    display: flex;
    align-items: center;
    gap: 20px;
}
.management__table__meta__img {
    padding: 6px;
    flex-shrink: 0;
    width: 113px;
    border: 1px solid var(--acn-theme-border-color, #dddddd);
}
.management__table__meta__img img {
    width: 100%;
    display: block;
}
.management__table__meta__title {
    margin: 0;
    font-size: 18px;
    color: var(--acn-theme-black8, #202221);
    font-weight: 700;
    line-height: 1.277;
}
.management__table__meta__title:hover {
    color: var(--acn-theme-base, #0365d3);
}
.management__table__meta__title a {
    color: inherit;
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
    transition: all 500ms ease;
}
.management__table__meta__title a:hover {
    background-size: 100% 1px;
}

.modal {
    display: block !important;
    background-color: rgba(0, 0, 0, 0.3);

}

.disabledElement {
    pointer-events: none !important;
}

@keyframes rotate {
    100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

.btn {
    cursor: pointer;
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    -webkit-appearance: none;
    border: none;
    outline: none !important;
    padding: 5px 10px;
    background-color: var(--acn-theme-black3, #19232b);
    font-size: 13px;
    color: var(--acn-theme-white, #ffffff);
    font-weight: 400;
    border-radius: 100px;
    z-index: 1;
    transition: all 500ms ease;
}

.btn-outline-danger {
    background-color: transparent;
    border : 1px solid #ee9982;
    color : #ee9982;
}

.btn-outline-danger:hover{
    background-color: #ee9982;
}

.btn-outline-success {
    background-color: transparent;
    border : 1px solid #1ca54d;
    color : #1ca54d;
}

.btn-outline-success:hover{
    background-color: #1ca54d;
}

.btn-icon {
    padding-right: 50px;
}

.btn-icon:hover {
    border : 1px solid #ee9982;
    color : white;
}

.form-control-select__multi-value {
    margin : 0 !important;
    margin-right: 2px !important;
}

.form-control-select__multi-value__label {
    padding-top : 0 !important;
    padding-bottom : 0 !important;
}

.form-control-select__value-container {
    padding-left: 4px !important;
}

.form-control-select__menu {
    z-index: 3 !important;
}

.modal-title {
    margin-top : 0!important;
}

.form-control-select__placeholder {
    display: none !important;
}

.form-control-select__control {
    border-radius: 0.375rem !important;
    height: 40px !important;
}

.editable-element-container {
    position: absolute;
    top:0;
    left : 0;
    right: 0;
    bottom : 0;
    z-index: 99;
    opacity: 0;
    background-color: rgba(255,255,255,0.8);
    display: flex;
    justify-content: center;
}

.editable-element-container:hover {
    opacity: 1;
}

.text-right {
    text-align: right!important;
}

.management-panel {
    font-family: var(--theme-font-menu_font-family) !important;
}

.error {
    font-size: 12px !important;
}

label.error {
    position: absolute;
    bottom: -5px;
    left: 26px;
}