.custom-node .btn {
    display: none !important;
}

.custom-node:hover .btn {
    display: inline-block !important;
}

[role="tree"] > div:first-of-type {
    overflow-x : hidden !important;
}

.node-element {
    border : 1px solid transparent;
    border-radius: 5px;
    padding : 5px 10px;
    background-color: #ecf2f2;
    cursor: move;
}

.node-actions {
    float: right;
}