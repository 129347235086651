.faq-accordion {
    position: relative;
    z-index: 1;
}
.faq-accordion .accordion + .accordion {
    margin-top: 30px;
}
.faq-accordion .accordion {
    background-color: var(--acn-theme-white, #ffffff);
    border-radius: 30px;
    border: 1px solid var(--acn-theme-border-color, #dddddd);
}
.faq-accordion .accordion-title {
    position: relative;
    padding: 7.5px 50px 7.5px 10px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 30px;
}
.faq-accordion .accordion-title::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    background-color: var(--acn-theme-black5, #E6B088FF);
    opacity: 0;
    transition: all 500ms ease;
}
.faq-accordion .accordion-title::after {
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 16px;
    color: var(--acn-theme-black5, #E6B088FF);
    position: absolute;
    top: 50%;
    right: 23px;
    transform: translateY(-50%);
    z-index: 1;
    transition: all 500ms ease;
}
.faq-accordion .accordion-title__icon {
    position: relative;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: var(--acn-theme-base, #E6B088FF);
    font-size: 12px;
    color: var(--acn-theme-white, #ffffff);
    border-radius: 50%;
    z-index: 1;
    transition: all 500ms ease;
}
.faq-accordion .accordion-title h4 {
    margin: 0;
    position: relative;
    font-size: 14px;
    color: var(--acn-theme-black5, #E6B088FF);
    font-weight: 500;
    line-height: 1.625;
    transition: all 500ms ease;
}
.faq-accordion .active .accordion-title::before {
    opacity: 1;
}
.faq-accordion .active .accordion-title::after {
    color: var(--acn-theme-white, #ffffff);
    transform: translateY(-50%) rotate(180deg);
}
.faq-accordion .active .accordion-title__icon {
    color: var(--acn-theme-base, #E6B088FF);
    background-color: var(--acn-theme-white, #ffffff);
    transform: rotate(45deg);
}
.faq-accordion .active .accordion-title h4 {
    color: var(--acn-theme-white, #ffffff);
}
.faq-accordion .accordion-content .inner {
    padding: 18px 37px 20px;
}
.faq-accordion .accordion-content p {
    position: relative;
    margin: 0;
}
.faq-accordion .accordion-content p::before {
    content: "";
    width: 2px;
    height: calc(100% - 14px);
    position: absolute;
    top: 50%;
    left: -11px;
    transform: translateY(-50%);
    background-color: var(--acn-theme-base, #E6B088FF);
}
